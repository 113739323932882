/* tslint:disable */

/* eslint-disable */

/**
 * Vapaus
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { mapValues } from "../runtime";
import type { BenefitDefinitionSummaryRead } from "./BenefitDefinitionSummaryRead";
import {
  BenefitDefinitionSummaryReadFromJSON,
  BenefitDefinitionSummaryReadFromJSONTyped,
  BenefitDefinitionSummaryReadToJSON,
  BenefitDefinitionSummaryReadToJSONTyped,
} from "./BenefitDefinitionSummaryRead";
import type { BikeBenefitOrderState } from "./BikeBenefitOrderState";
import {
  BikeBenefitOrderStateFromJSON,
  BikeBenefitOrderStateFromJSONTyped,
  BikeBenefitOrderStateToJSON,
  BikeBenefitOrderStateToJSONTyped,
} from "./BikeBenefitOrderState";
import type { BikeSummaryRead } from "./BikeSummaryRead";
import {
  BikeSummaryReadFromJSON,
  BikeSummaryReadFromJSONTyped,
  BikeSummaryReadToJSON,
  BikeSummaryReadToJSONTyped,
} from "./BikeSummaryRead";
import type { UserSummaryRead } from "./UserSummaryRead";
import {
  UserSummaryReadFromJSON,
  UserSummaryReadFromJSONTyped,
  UserSummaryReadToJSON,
  UserSummaryReadToJSONTyped,
} from "./UserSummaryRead";

/**
 *
 * @export
 * @interface BikeBenefitOrderSummaryRead
 */
export interface BikeBenefitOrderSummaryRead {
  /**
   *
   * @type {string}
   * @memberof BikeBenefitOrderSummaryRead
   */
  id: string;
  /**
   *
   * @type {string}
   * @memberof BikeBenefitOrderSummaryRead
   */
  vapausCode: string;
  /**
   *
   * @type {UserSummaryRead}
   * @memberof BikeBenefitOrderSummaryRead
   */
  user?: UserSummaryRead;
  /**
   *
   * @type {BenefitDefinitionSummaryRead}
   * @memberof BikeBenefitOrderSummaryRead
   */
  benefitDefinition: BenefitDefinitionSummaryRead;
  /**
   *
   * @type {BikeSummaryRead}
   * @memberof BikeBenefitOrderSummaryRead
   */
  bike: BikeSummaryRead;
  /**
   *
   * @type {BikeBenefitOrderState}
   * @memberof BikeBenefitOrderSummaryRead
   */
  state: BikeBenefitOrderState;
  /**
   *
   * @type {number}
   * @memberof BikeBenefitOrderSummaryRead
   */
  totalPackagePrice: number;
  /**
   *
   * @type {number}
   * @memberof BikeBenefitOrderSummaryRead
   */
  totalPackagePriceWithoutMaintenance: number;
  /**
   *
   * @type {Date}
   * @memberof BikeBenefitOrderSummaryRead
   */
  updatedAt?: Date;
}

/**
 * Check if a given object implements the BikeBenefitOrderSummaryRead interface.
 */
export function instanceOfBikeBenefitOrderSummaryRead(
  value: object,
): value is BikeBenefitOrderSummaryRead {
  if (!("id" in value) || value["id"] === undefined) return false;
  if (!("vapausCode" in value) || value["vapausCode"] === undefined)
    return false;
  if (
    !("benefitDefinition" in value) ||
    value["benefitDefinition"] === undefined
  )
    return false;
  if (!("bike" in value) || value["bike"] === undefined) return false;
  if (!("state" in value) || value["state"] === undefined) return false;
  if (
    !("totalPackagePrice" in value) ||
    value["totalPackagePrice"] === undefined
  )
    return false;
  if (
    !("totalPackagePriceWithoutMaintenance" in value) ||
    value["totalPackagePriceWithoutMaintenance"] === undefined
  )
    return false;
  return true;
}

export function BikeBenefitOrderSummaryReadFromJSON(
  json: any,
): BikeBenefitOrderSummaryRead {
  return BikeBenefitOrderSummaryReadFromJSONTyped(json, false);
}

export function BikeBenefitOrderSummaryReadFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): BikeBenefitOrderSummaryRead {
  if (json == null) {
    return json;
  }
  return {
    id: json["id"],
    vapausCode: json["vapaus_code"],
    user:
      json["user"] == null ? undefined : UserSummaryReadFromJSON(json["user"]),
    benefitDefinition: BenefitDefinitionSummaryReadFromJSON(
      json["benefit_definition"],
    ),
    bike: BikeSummaryReadFromJSON(json["bike"]),
    state: BikeBenefitOrderStateFromJSON(json["state"]),
    totalPackagePrice: json["total_package_price"],
    totalPackagePriceWithoutMaintenance:
      json["total_package_price_without_maintenance"],
    updatedAt:
      json["updated_at"] == null ? undefined : new Date(json["updated_at"]),
  };
}

export function BikeBenefitOrderSummaryReadToJSON(
  json: any,
): BikeBenefitOrderSummaryRead {
  return BikeBenefitOrderSummaryReadToJSONTyped(json, false);
}

export function BikeBenefitOrderSummaryReadToJSONTyped(
  value?: BikeBenefitOrderSummaryRead | null,
  ignoreDiscriminator: boolean = false,
): any {
  if (value == null) {
    return value;
  }

  return {
    id: value["id"],
    vapaus_code: value["vapausCode"],
    user: UserSummaryReadToJSON(value["user"]),
    benefit_definition: BenefitDefinitionSummaryReadToJSON(
      value["benefitDefinition"],
    ),
    bike: BikeSummaryReadToJSON(value["bike"]),
    state: BikeBenefitOrderStateToJSON(value["state"]),
    total_package_price: value["totalPackagePrice"],
    total_package_price_without_maintenance:
      value["totalPackagePriceWithoutMaintenance"],
    updated_at:
      value["updatedAt"] == null ? undefined : value["updatedAt"].toISOString(),
  };
}
