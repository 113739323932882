import { sortBy } from "lodash-es";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";

import {
  BenefitDefinitionReadWithOrganisationSummary,
  Currency,
  SupportedOrganisationCountries,
} from "@vapaus/api-codegen";
import { NumberInput, Select } from "@vapaus/form";
import { useCurrencyFormat } from "@vapaus/i18n";
import { Card, Flex, RowItem, Typography } from "@vapaus/ui-v2";

import { getMonthlyPaymentLabel } from "../../../../../utils/getMonthlyPaymentLabel";
import { OrderFormCalculatedParams } from "../../hooks";
import { BenefitOrderFormType } from "../../utils";
import { BaseOrderFormSectionProps } from "../types";

export type LeasingContractFormProps = {
  benefitDefinition?: BenefitDefinitionReadWithOrganisationSummary;
  formSummary: OrderFormCalculatedParams;
} & BaseOrderFormSectionProps;

export function LeasingContractForm({
  benefitDefinition,
  formSummary,
  currency = Currency.Eur,
}: LeasingContractFormProps) {
  const { t } = useTranslation();
  const formatCurrency = useCurrencyFormat(currency);
  const {
    watch,
    formState: { errors },
  } = useFormContext<BenefitOrderFormType>();
  const [leasingPeriodMonths, fixedMonthlyTaxableValue] = watch([
    "leasingPeriodMonths",
    "fixedMonthlyTaxableValue",
  ]);
  const isLeasingPeriodEditable = !formSummary.hasFixedTaxableValue;
  const fixedTaxableValuesLength =
    benefitDefinition?.fixedMonthlyTaxableValues?.length ?? 0;
  const isFixedMonthlyTaxableValueEditable =
    formSummary.hasFixedTaxableValue && fixedTaxableValuesLength > 1;
  const isBenefitSwedish =
    benefitDefinition?.organisation.country ===
    SupportedOrganisationCountries.Se;

  const fixedMonthlyPaymentText = t(
    "shopV2:orderForm.leasingContract.fixedAmount",
  );
  const calculatedMonthlyPaymentText = t(
    "shopV2:orderForm.leasingContract.calculatedPayment",
  );
  const calculatedBasedOnBikePackageText = t(
    "shopV2:orderForm.leasingContract.calculatedBasedOnBikePackage",
  );
  const calculatedBasedOnMonthlyPaymentText = isBenefitSwedish
    ? t("shopV2:orderForm.leasingContract.calculatedBasedOnMonthlyRentalFee")
    : t("shopV2:orderForm.leasingContract.calculatedBasedOnMonthlyPayment");

  const monthlyPaymentLabel = getMonthlyPaymentLabel(t, benefitDefinition);

  return (
    <Card
      title={t("shopV2:orderForm.leasingContract.title")}
      subtitle={t("shopV2:orderForm.leasingContract.subtitle")}
    >
      <Flex direction="column" gap="md">
        <Flex direction="column" gap="md">
          {isFixedMonthlyTaxableValueEditable ? (
            <Select
              name="fixedMonthlyTaxableValue"
              options={sortBy(
                benefitDefinition?.fixedMonthlyTaxableValues || [],
              ).map((value) => ({
                value,
                label: formatCurrency(value),
              }))}
              label={monthlyPaymentLabel}
            />
          ) : (
            <Flex direction="column" gap="xxs">
              <RowItem
                label={monthlyPaymentLabel}
                value={formatCurrency(fixedMonthlyTaxableValue)}
                valueIsBold
              />
              <Typography>
                {formSummary.hasFixedTaxableValue
                  ? fixedMonthlyPaymentText
                  : calculatedMonthlyPaymentText}
              </Typography>
            </Flex>
          )}
          {isBenefitSwedish ? (
            <>
              <Flex direction="column" gap="xxs">
                <RowItem
                  label={t(
                    "shopV2:orderForm.leasingContract.sweden.grossSalaryDeduction",
                  )}
                  value="-"
                  valueIsBold
                />
                <Typography>
                  {t(
                    "shopV2:orderForm.leasingContract.sweden.calculatedWhenOrderCreated",
                  )}
                </Typography>
              </Flex>
              <Flex direction="column" gap="xxs">
                <RowItem
                  label={t(
                    "shopV2:orderForm.leasingContract.sweden.benefitValue",
                  )}
                  value="-"
                  valueIsBold
                />
                <Typography>
                  {t(
                    "shopV2:orderForm.leasingContract.sweden.calculatedWhenOrderCreated",
                  )}
                </Typography>
              </Flex>
            </>
          ) : null}
        </Flex>
        <Flex direction="column" gap="xxs">
          {isLeasingPeriodEditable ? (
            <NumberInput
              name="leasingPeriodMonths"
              label={t(
                "shopV2:orderForm.leasingContract.leasingPeriodInputLabel",
                {
                  min: benefitDefinition?.minimumLeasingPeriod,
                  max: benefitDefinition?.maximumLeasingPeriod,
                },
              )}
              placeholder={t(
                "shopV2:orderForm.leasingContract.enterLeasingPeriods",
              )}
              min={benefitDefinition?.minimumLeasingPeriod}
              max={benefitDefinition?.maximumLeasingPeriod}
              decimals={0}
            />
          ) : (
            <>
              <RowItem
                label={t("shopV2:orderForm.leasingContract.leasingPeriods")}
                value={
                  !errors.root?.message
                    ? t(
                        "shopV2:orderForm.leasingContract.leasingPeriodMonths",
                        { months: leasingPeriodMonths ?? "-" },
                      )
                    : "-"
                }
                valueIsBold
              />
              <Typography>
                {isFixedMonthlyTaxableValueEditable
                  ? calculatedBasedOnMonthlyPaymentText
                  : calculatedBasedOnBikePackageText}
              </Typography>
            </>
          )}
        </Flex>
      </Flex>
    </Card>
  );
}
