import { Currency } from "@vapaus/api-codegen";
import { useCurrencyFormat } from "@vapaus/i18n";
import { Typography } from "@vapaus/ui-v2";

import { Price } from "../../types/Price";

type PriceCellProps = {
  price: Price;
};

export function PriceCell({ price }: PriceCellProps) {
  const formatCurrency = useCurrencyFormat(price.currency as Currency);

  return (
    <Typography align="flex-end">{formatCurrency(price.total)}</Typography>
  );
}
