import { SupportedCountriesEnum } from "@vapaus/api-codegen";

import { useShopContext } from "../../../contexts/ShopContext";
import { ContactUsCardDefault } from "./ContactUsCardDefault";
import { ContactUsCardSweden } from "./ContactUsCardSweden";

export function ContactUsCard() {
  const { selectedShop } = useShopContext();

  return selectedShop?.country === SupportedCountriesEnum.Se ? (
    <ContactUsCardSweden />
  ) : (
    <ContactUsCardDefault />
  );
}
