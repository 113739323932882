/* tslint:disable */

/* eslint-disable */

/**
 * Vapaus
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { mapValues } from "../runtime";
import type { Currency } from "./Currency";
import {
  CurrencyFromJSON,
  CurrencyFromJSONTyped,
  CurrencyToJSON,
  CurrencyToJSONTyped,
} from "./Currency";

/**
 *
 * @export
 * @interface SaleInvoiceTransaction
 */
export interface SaleInvoiceTransaction {
  /**
   *
   * @type {string}
   * @memberof SaleInvoiceTransaction
   */
  saleInvoiceId: string;
  /**
   *
   * @type {string}
   * @memberof SaleInvoiceTransaction
   */
  paymentId?: string;
  /**
   *
   * @type {Date}
   * @memberof SaleInvoiceTransaction
   */
  date: Date;
  /**
   *
   * @type {number}
   * @memberof SaleInvoiceTransaction
   */
  amount: number;
  /**
   *
   * @type {Currency}
   * @memberof SaleInvoiceTransaction
   */
  currency: Currency;
  /**
   *
   * @type {string}
   * @memberof SaleInvoiceTransaction
   */
  description?: string;
  /**
   *
   * @type {string}
   * @memberof SaleInvoiceTransaction
   */
  id?: string;
  /**
   *
   * @type {Date}
   * @memberof SaleInvoiceTransaction
   */
  createdAt?: Date;
  /**
   *
   * @type {Date}
   * @memberof SaleInvoiceTransaction
   */
  updatedAt?: Date;
}

/**
 * Check if a given object implements the SaleInvoiceTransaction interface.
 */
export function instanceOfSaleInvoiceTransaction(
  value: object,
): value is SaleInvoiceTransaction {
  if (!("saleInvoiceId" in value) || value["saleInvoiceId"] === undefined)
    return false;
  if (!("date" in value) || value["date"] === undefined) return false;
  if (!("amount" in value) || value["amount"] === undefined) return false;
  if (!("currency" in value) || value["currency"] === undefined) return false;
  return true;
}

export function SaleInvoiceTransactionFromJSON(
  json: any,
): SaleInvoiceTransaction {
  return SaleInvoiceTransactionFromJSONTyped(json, false);
}

export function SaleInvoiceTransactionFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): SaleInvoiceTransaction {
  if (json == null) {
    return json;
  }
  return {
    saleInvoiceId: json["sale_invoice_id"],
    paymentId: json["payment_id"] == null ? undefined : json["payment_id"],
    date: new Date(json["date"]),
    amount: json["amount"],
    currency: CurrencyFromJSON(json["currency"]),
    description: json["description"] == null ? undefined : json["description"],
    id: json["id"] == null ? undefined : json["id"],
    createdAt:
      json["created_at"] == null ? undefined : new Date(json["created_at"]),
    updatedAt:
      json["updated_at"] == null ? undefined : new Date(json["updated_at"]),
  };
}

export function SaleInvoiceTransactionToJSON(
  json: any,
): SaleInvoiceTransaction {
  return SaleInvoiceTransactionToJSONTyped(json, false);
}

export function SaleInvoiceTransactionToJSONTyped(
  value?: SaleInvoiceTransaction | null,
  ignoreDiscriminator: boolean = false,
): any {
  if (value == null) {
    return value;
  }

  return {
    sale_invoice_id: value["saleInvoiceId"],
    payment_id: value["paymentId"],
    date: value["date"].toISOString().substring(0, 10),
    amount: value["amount"],
    currency: CurrencyToJSON(value["currency"]),
    description: value["description"],
    id: value["id"],
    created_at:
      value["createdAt"] == null ? undefined : value["createdAt"].toISOString(),
    updated_at:
      value["updatedAt"] == null ? undefined : value["updatedAt"].toISOString(),
  };
}
