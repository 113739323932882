/* tslint:disable */

/* eslint-disable */

/**
 * Vapaus
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { mapValues } from "../runtime";

/**
 *
 * @export
 * @interface ShopBikeBrand
 */
export interface ShopBikeBrand {
  /**
   *
   * @type {string}
   * @memberof ShopBikeBrand
   */
  shopId: string;
  /**
   *
   * @type {string}
   * @memberof ShopBikeBrand
   */
  bikeBrandId: string;
  /**
   *
   * @type {string}
   * @memberof ShopBikeBrand
   */
  id?: string;
  /**
   *
   * @type {Date}
   * @memberof ShopBikeBrand
   */
  createdAt?: Date;
  /**
   *
   * @type {Date}
   * @memberof ShopBikeBrand
   */
  updatedAt?: Date;
}

/**
 * Check if a given object implements the ShopBikeBrand interface.
 */
export function instanceOfShopBikeBrand(value: object): value is ShopBikeBrand {
  if (!("shopId" in value) || value["shopId"] === undefined) return false;
  if (!("bikeBrandId" in value) || value["bikeBrandId"] === undefined)
    return false;
  return true;
}

export function ShopBikeBrandFromJSON(json: any): ShopBikeBrand {
  return ShopBikeBrandFromJSONTyped(json, false);
}

export function ShopBikeBrandFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): ShopBikeBrand {
  if (json == null) {
    return json;
  }
  return {
    shopId: json["shop_id"],
    bikeBrandId: json["bike_brand_id"],
    id: json["id"] == null ? undefined : json["id"],
    createdAt:
      json["created_at"] == null ? undefined : new Date(json["created_at"]),
    updatedAt:
      json["updated_at"] == null ? undefined : new Date(json["updated_at"]),
  };
}

export function ShopBikeBrandToJSON(json: any): ShopBikeBrand {
  return ShopBikeBrandToJSONTyped(json, false);
}

export function ShopBikeBrandToJSONTyped(
  value?: ShopBikeBrand | null,
  ignoreDiscriminator: boolean = false,
): any {
  if (value == null) {
    return value;
  }

  return {
    shop_id: value["shopId"],
    bike_brand_id: value["bikeBrandId"],
    id: value["id"],
    created_at:
      value["createdAt"] == null ? undefined : value["createdAt"].toISOString(),
    updated_at:
      value["updatedAt"] == null ? undefined : value["updatedAt"].toISOString(),
  };
}
