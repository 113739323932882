import { sumBy } from "lodash-es";
import { useTranslation } from "react-i18next";

import { useCurrencyFormat, usePercentFormat } from "@vapaus/i18n";
import { Flex, HorizontalLine, RowItem } from "@vapaus/ui-v2";

import { BaseOrderViewProps } from "../../type";
import { AccessoryItem } from "./AccessoryItem";
import { OrderSectionWithEdit } from "./OrderSectionWithEdit";

export function Accessories({ order, benefitDefinition }: BaseOrderViewProps) {
  const { t } = useTranslation();
  const { accessories = [], discountAccessory, currency, flatVatRate } = order;
  const formatCurrency = useCurrencyFormat(currency);
  const formatPercent = usePercentFormat();
  const accessoryTotal = sumBy(accessories, "purchasePrice");
  const discountedPartnerTotal = accessoryTotal * (1 - discountAccessory);
  if (accessories.length === 0) return null;
  return (
    <OrderSectionWithEdit
      title={t("shopV2:orderViewPage.accessories.title")}
      order={order}
      benefitDefinition={benefitDefinition}
      editingSection="Accessories"
    >
      <Flex direction="column" gap="md">
        {accessories.map((accessory) => (
          <AccessoryItem
            key={accessory.id}
            accessory={accessory}
            accessoryDiscount={discountAccessory}
          />
        ))}
      </Flex>
      <HorizontalLine />
      <RowItem
        label={t("shopV2:orderViewPage.totalWithVat", {
          value: formatPercent(flatVatRate),
        })}
        value={formatCurrency(accessoryTotal)}
        valueIsBold
      />
      <RowItem
        label={t("shopV2:orderViewPage.accessories.discountedTotal")}
        value={formatCurrency(discountedPartnerTotal)}
        typoVariant="bodySm"
        labelIsBold={false}
      />
    </OrderSectionWithEdit>
  );
}
