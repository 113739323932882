/* tslint:disable */

/* eslint-disable */

/**
 * Vapaus
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { mapValues } from "../runtime";

/**
 *
 * @export
 * @interface ShopSummaryRead
 */
export interface ShopSummaryRead {
  /**
   *
   * @type {string}
   * @memberof ShopSummaryRead
   */
  id: string;
  /**
   *
   * @type {string}
   * @memberof ShopSummaryRead
   */
  name: string;
}

/**
 * Check if a given object implements the ShopSummaryRead interface.
 */
export function instanceOfShopSummaryRead(
  value: object,
): value is ShopSummaryRead {
  if (!("id" in value) || value["id"] === undefined) return false;
  if (!("name" in value) || value["name"] === undefined) return false;
  return true;
}

export function ShopSummaryReadFromJSON(json: any): ShopSummaryRead {
  return ShopSummaryReadFromJSONTyped(json, false);
}

export function ShopSummaryReadFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): ShopSummaryRead {
  if (json == null) {
    return json;
  }
  return {
    id: json["id"],
    name: json["name"],
  };
}

export function ShopSummaryReadToJSON(json: any): ShopSummaryRead {
  return ShopSummaryReadToJSONTyped(json, false);
}

export function ShopSummaryReadToJSONTyped(
  value?: ShopSummaryRead | null,
  ignoreDiscriminator: boolean = false,
): any {
  if (value == null) {
    return value;
  }

  return {
    id: value["id"],
    name: value["name"],
  };
}
