import { useTranslation } from "react-i18next";

import { useCurrencyFormat } from "@vapaus/i18n";
import {
  Card,
  Flex,
  HorizontalLine,
  RowItem,
  TextButton,
  Typography,
} from "@vapaus/ui-v2";

import { BaseOrderViewProps } from "../../type";
import { useOrderSummary } from "../hooks/useOrderSummary";

export function BillingDetails({
  order,
  benefitDefinition,
}: BaseOrderViewProps) {
  const { t } = useTranslation();
  const formatCurrency = useCurrencyFormat(order.currency);
  const { totalBikePackage, partnerDiscount, partnerInvoice } = useOrderSummary(
    order,
    benefitDefinition,
  );

  return (
    <Card title={t("shopV2:orderViewPage.billingDetails.title")}>
      <Flex direction="column" gap="xs">
        <RowItem
          label={t("shopV2:orderViewPage.billingDetails.bikePackage")}
          value={formatCurrency(totalBikePackage)}
        />
        <RowItem
          label={t("shopV2:orderViewPage.billingDetails.partnerDiscount")}
          value={formatCurrency(
            partnerDiscount !== null ? partnerDiscount * -1 : null,
          )}
        />
        <RowItem
          label={t("shopV2:orderViewPage.billingDetails.invoiceTotal")}
          value={formatCurrency(partnerInvoice)}
          valueIsBold
        />
        <HorizontalLine />
        <Flex direction="column" align="center" gap="xs">
          <Typography>
            {t("shopV2:orderViewPage.billingDetails.needHelp")}
          </Typography>
          <TextButton
            to={t("shopV2:support.guides.invoicing.link")}
            target="_blank"
            size="md"
          >
            {t("shopV2:orderViewPage.billingDetails.instruction")}
          </TextButton>
        </Flex>
      </Flex>
    </Card>
  );
}
