/* tslint:disable */

/* eslint-disable */

/**
 * Vapaus
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { mapValues } from "../runtime";
import type { BikeSummaryRead } from "./BikeSummaryRead";
import {
  BikeSummaryReadFromJSON,
  BikeSummaryReadFromJSONTyped,
  BikeSummaryReadToJSON,
  BikeSummaryReadToJSONTyped,
} from "./BikeSummaryRead";
import type { Currency } from "./Currency";
import {
  CurrencyFromJSON,
  CurrencyFromJSONTyped,
  CurrencyToJSON,
  CurrencyToJSONTyped,
} from "./Currency";
import type { SupportedOrganisationCountries } from "./SupportedOrganisationCountries";
import {
  SupportedOrganisationCountriesFromJSON,
  SupportedOrganisationCountriesFromJSONTyped,
  SupportedOrganisationCountriesToJSON,
  SupportedOrganisationCountriesToJSONTyped,
} from "./SupportedOrganisationCountries";
import type { UserSummaryRead } from "./UserSummaryRead";
import {
  UserSummaryReadFromJSON,
  UserSummaryReadFromJSONTyped,
  UserSummaryReadToJSON,
  UserSummaryReadToJSONTyped,
} from "./UserSummaryRead";

/**
 *
 * @export
 * @interface BikeBenefitContractForMaintenanceRead
 */
export interface BikeBenefitContractForMaintenanceRead {
  /**
   *
   * @type {string}
   * @memberof BikeBenefitContractForMaintenanceRead
   */
  id: string;
  /**
   *
   * @type {number}
   * @memberof BikeBenefitContractForMaintenanceRead
   */
  totalMaintenanceBudget: number;
  /**
   *
   * @type {number}
   * @memberof BikeBenefitContractForMaintenanceRead
   */
  spentMaintenanceBudget: number;
  /**
   *
   * @type {number}
   * @memberof BikeBenefitContractForMaintenanceRead
   */
  remainingMaintenanceBudget: number;
  /**
   *
   * @type {number}
   * @memberof BikeBenefitContractForMaintenanceRead
   */
  monthlyMaintenanceBudget: number;
  /**
   *
   * @type {number}
   * @memberof BikeBenefitContractForMaintenanceRead
   */
  leasingPeriodMonths: number;
  /**
   *
   * @type {BikeSummaryRead}
   * @memberof BikeBenefitContractForMaintenanceRead
   */
  bike: BikeSummaryRead;
  /**
   *
   * @type {UserSummaryRead}
   * @memberof BikeBenefitContractForMaintenanceRead
   */
  user: UserSummaryRead;
  /**
   *
   * @type {string}
   * @memberof BikeBenefitContractForMaintenanceRead
   */
  vapausCode: string;
  /**
   *
   * @type {Currency}
   * @memberof BikeBenefitContractForMaintenanceRead
   */
  currency: Currency;
  /**
   *
   * @type {SupportedOrganisationCountries}
   * @memberof BikeBenefitContractForMaintenanceRead
   */
  benefitCountry: SupportedOrganisationCountries;
  /**
   *
   * @type {number}
   * @memberof BikeBenefitContractForMaintenanceRead
   */
  initialVatRate: number;
}

/**
 * Check if a given object implements the BikeBenefitContractForMaintenanceRead interface.
 */
export function instanceOfBikeBenefitContractForMaintenanceRead(
  value: object,
): value is BikeBenefitContractForMaintenanceRead {
  if (!("id" in value) || value["id"] === undefined) return false;
  if (
    !("totalMaintenanceBudget" in value) ||
    value["totalMaintenanceBudget"] === undefined
  )
    return false;
  if (
    !("spentMaintenanceBudget" in value) ||
    value["spentMaintenanceBudget"] === undefined
  )
    return false;
  if (
    !("remainingMaintenanceBudget" in value) ||
    value["remainingMaintenanceBudget"] === undefined
  )
    return false;
  if (
    !("monthlyMaintenanceBudget" in value) ||
    value["monthlyMaintenanceBudget"] === undefined
  )
    return false;
  if (
    !("leasingPeriodMonths" in value) ||
    value["leasingPeriodMonths"] === undefined
  )
    return false;
  if (!("bike" in value) || value["bike"] === undefined) return false;
  if (!("user" in value) || value["user"] === undefined) return false;
  if (!("vapausCode" in value) || value["vapausCode"] === undefined)
    return false;
  if (!("currency" in value) || value["currency"] === undefined) return false;
  if (!("benefitCountry" in value) || value["benefitCountry"] === undefined)
    return false;
  if (!("initialVatRate" in value) || value["initialVatRate"] === undefined)
    return false;
  return true;
}

export function BikeBenefitContractForMaintenanceReadFromJSON(
  json: any,
): BikeBenefitContractForMaintenanceRead {
  return BikeBenefitContractForMaintenanceReadFromJSONTyped(json, false);
}

export function BikeBenefitContractForMaintenanceReadFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): BikeBenefitContractForMaintenanceRead {
  if (json == null) {
    return json;
  }
  return {
    id: json["id"],
    totalMaintenanceBudget: json["total_maintenance_budget"],
    spentMaintenanceBudget: json["spent_maintenance_budget"],
    remainingMaintenanceBudget: json["remaining_maintenance_budget"],
    monthlyMaintenanceBudget: json["monthly_maintenance_budget"],
    leasingPeriodMonths: json["leasing_period_months"],
    bike: BikeSummaryReadFromJSON(json["bike"]),
    user: UserSummaryReadFromJSON(json["user"]),
    vapausCode: json["vapaus_code"],
    currency: CurrencyFromJSON(json["currency"]),
    benefitCountry: SupportedOrganisationCountriesFromJSON(
      json["benefit_country"],
    ),
    initialVatRate: json["initial_vat_rate"],
  };
}

export function BikeBenefitContractForMaintenanceReadToJSON(
  json: any,
): BikeBenefitContractForMaintenanceRead {
  return BikeBenefitContractForMaintenanceReadToJSONTyped(json, false);
}

export function BikeBenefitContractForMaintenanceReadToJSONTyped(
  value?: BikeBenefitContractForMaintenanceRead | null,
  ignoreDiscriminator: boolean = false,
): any {
  if (value == null) {
    return value;
  }

  return {
    id: value["id"],
    total_maintenance_budget: value["totalMaintenanceBudget"],
    spent_maintenance_budget: value["spentMaintenanceBudget"],
    remaining_maintenance_budget: value["remainingMaintenanceBudget"],
    monthly_maintenance_budget: value["monthlyMaintenanceBudget"],
    leasing_period_months: value["leasingPeriodMonths"],
    bike: BikeSummaryReadToJSON(value["bike"]),
    user: UserSummaryReadToJSON(value["user"]),
    vapaus_code: value["vapausCode"],
    currency: CurrencyToJSON(value["currency"]),
    benefit_country: SupportedOrganisationCountriesToJSON(
      value["benefitCountry"],
    ),
    initial_vat_rate: value["initialVatRate"],
  };
}
