import { useState } from "react";

import { useTranslation } from "react-i18next";
import styled, { css } from "styled-components";

import {
  BikeBenefitContractForMaintenanceRead,
  Currency,
} from "@vapaus/api-codegen";
import { useCurrencyFormat } from "@vapaus/i18n";
import {
  Button,
  Card,
  Flex,
  RowItem,
  Separator,
  Typography,
} from "@vapaus/ui-v2";

import { AddMaintenanceEventModal } from "./AddMaintenanceEventModal";
import { ServiceHistoryTable } from "./components/ServiceHistoryTable";

type BikeMaintenanceViewProps = {
  contract: BikeBenefitContractForMaintenanceRead;
  includeHeader?: boolean;
};

export function BikeView({
  contract,
  includeHeader = true,
}: BikeMaintenanceViewProps) {
  const { t } = useTranslation();
  const formatCurrency = useCurrencyFormat(contract.currency as Currency);
  const [remainingBudget, setRemainingBudget] = useState<number>(
    contract.remainingMaintenanceBudget,
  );
  const [spentBudget, setSpentBudget] = useState<number>(
    contract.spentMaintenanceBudget,
  );
  const [isFormModalOpen, setIsFormModalOpen] = useState<boolean>(false);

  const handleCloseFormModal = () => setIsFormModalOpen(false);
  const handleOpenFormModal = () => setIsFormModalOpen(true);
  const handleBudgetChange = (budgetDifference: number) => {
    setRemainingBudget(
      (previousRemainingBudget) => previousRemainingBudget - budgetDifference,
    );
    setSpentBudget(
      (previousSpentBudget) => previousSpentBudget + budgetDifference,
    );
  };

  return (
    <Flex
      justify="center"
      padding={includeHeader ? "xl" : "none"}
      xsPadding="md"
    >
      <StyledFlexContainer direction="column" gap="md">
        {includeHeader ? (
          <StyledHeader direction="column" gap="sm" align="center">
            <Typography variant="h6">
              {contract.user.fullName} · {contract.vapausCode} ·{" "}
              {contract.bike.serialNumber}
            </Typography>
            <Typography variant="h1">
              {contract.bike.bikeBrand.name} {contract.bike.model}
            </Typography>
          </StyledHeader>
        ) : null}
        <Flex gap="md" align="stretch" smDirection="column">
          <Card>
            <Flex direction="column" gap="lg">
              <Flex direction="column" gap="xxs" align="center">
                <Typography align="center" variant="h2">
                  {t("shopV2:maintenance.wizard.bikeView.maintenanceBudget")}
                </Typography>
                <Typography>
                  {t("shopV2:maintenance.wizard.bikeView.xMonths", {
                    value: `${formatCurrency(
                      contract.monthlyMaintenanceBudget,
                    )} x ${contract.leasingPeriodMonths}`,
                  })}
                </Typography>
              </Flex>
              <Separator />
              <Flex direction="column">
                <RowItem
                  label={t("shopV2:maintenance.wizard.bikeView.plan")}
                  value={t("shopV2:maintenance.wizard.bikeView.perMonth", {
                    value: `${formatCurrency(
                      contract.monthlyMaintenanceBudget,
                    )}`,
                  })}
                />
                <RowItem
                  label={t("shopV2:maintenance.wizard.bikeView.contractLength")}
                  value={t("shopV2:maintenance.wizard.bikeView.xMonths", {
                    value: contract.leasingPeriodMonths,
                  })}
                />
                <RowItem
                  label={t("shopV2:maintenance.wizard.bikeView.totalBudget")}
                  value={formatCurrency(contract.totalMaintenanceBudget)}
                />
              </Flex>
            </Flex>
          </Card>
          <Card>
            <Flex direction="column" gap="lg">
              <Flex direction="column" gap="xxs" align="center">
                <Typography
                  variant="h2"
                  {...(remainingBudget <= 0 ? { color: "error" } : [])}
                >
                  {formatCurrency(remainingBudget)}
                </Typography>
                <Typography>
                  {t("shopV2:maintenance.wizard.bikeView.budgetRemaining")}
                </Typography>
              </Flex>
              <Flex gap="none">
                <StyledInnerCardFlexLeft
                  direction="column"
                  gap="xs"
                  align="center"
                >
                  <Typography weight="bold">
                    {t("shopV2:maintenance.wizard.bikeView.used")}
                  </Typography>
                  <Typography>{formatCurrency(spentBudget)}</Typography>
                </StyledInnerCardFlexLeft>
                <StyledInnerCardFlexRight
                  direction="column"
                  gap="xs"
                  align="center"
                >
                  <Typography weight="bold">
                    {t("shopV2:maintenance.wizard.bikeView.original")}
                  </Typography>
                  <Typography>
                    {formatCurrency(contract.totalMaintenanceBudget)}
                  </Typography>
                </StyledInnerCardFlexRight>
              </Flex>
              <Button
                disabled={remainingBudget <= 0}
                onClick={handleOpenFormModal}
              >
                {t("shopV2:maintenance.wizard.bikeView.addEvent")}
              </Button>
            </Flex>
          </Card>
        </Flex>
        <Card>
          <Flex direction="column" gap="md">
            <Typography variant="h2">
              {t("shopV2:maintenance.wizard.bikeView.serviceHistory")}
            </Typography>
            <ServiceHistoryTable frameNumber={contract.bike.serialNumber} />
          </Flex>
        </Card>
      </StyledFlexContainer>
      <AddMaintenanceEventModal
        isOpen={isFormModalOpen}
        onClose={handleCloseFormModal}
        contractId={contract.id}
        country={contract.benefitCountry}
        currency={contract.currency}
        handleBudgetChange={handleBudgetChange}
      />
    </Flex>
  );
}

const StyledFlexContainer = styled(Flex)`
  width: 60rem;

  ${({ theme }) =>
    theme.breakpoints.md.down(css`
      width: 54rem;
    `)}

  ${({ theme }) =>
    theme.breakpoints.sm.down(css`
      width: 42rem;
    `)}

  ${({ theme }) =>
    theme.breakpoints.xs.down(css`
      width: 21rem;
    `)}
`;

const StyledHeader = styled(Flex)`
  padding: ${({ theme }) => theme.spacing.xl} 0;
`;

const StyledInnerCardFlexLeft = styled(Flex)`
  padding: ${({ theme }) => theme.spacing.sm} 0;
  flex: 1;
  border-top: ${({ theme }) => theme.border.primary.light};
  border-right: ${({ theme }) => theme.border.primary.light};
`;

const StyledInnerCardFlexRight = styled(Flex)`
  padding: ${({ theme }) => theme.spacing.sm} 0;
  flex: 1;
  border-top: ${({ theme }) => theme.border.primary.light};
`;
