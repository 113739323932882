import { useCallback, useState } from "react";

import { useTranslation } from "react-i18next";

import {
  BenefitDefinitionReadWithOrganisationSummary,
  BikeBenefitOrderExtraRead,
  BikeBenefitOrderRead,
  BikeBenefitOrderState,
} from "@vapaus/api-codegen";
import { FullPageModal, Modal } from "@vapaus/ui-v2";

import { FormSectionType, OrderForm } from "../OrderForm";

type OrderEditingModalProps = {
  isOpen: boolean;
  onClose: () => void;
  order: BikeBenefitOrderExtraRead;
  benefitDefinition: BenefitDefinitionReadWithOrganisationSummary;
  editingSection?: FormSectionType;
};

export function OrderEditingModal({
  isOpen,
  onClose,
  order,
  benefitDefinition,
  editingSection,
}: OrderEditingModalProps) {
  const [formChangeStatus, setFormChangeStatus] = useState(false);
  const [isOpenConfirmationModal, setIsOpenConfirmationModal] = useState(false);
  const [isOpenPendingApprovalModal, setIsOpenPendingApprovalModal] =
    useState(false);
  const { t } = useTranslation();

  const handleOrderCreateSuccess = (newOrder: BikeBenefitOrderRead) => {
    if (
      newOrder.state === BikeBenefitOrderState.Draft &&
      order.state === BikeBenefitOrderState.ReadyForDelivery
    ) {
      setIsOpenPendingApprovalModal(true);
    }
    onClose();
  };

  const handleModalClose = useCallback(() => {
    if (formChangeStatus) setIsOpenConfirmationModal(true);
    else onClose();
  }, [formChangeStatus, onClose]);

  const handleCloseConfimationModal = () => setIsOpenConfirmationModal(false);

  const handleConfirmConfimationModal = () => {
    setIsOpenConfirmationModal(false);
    setIsOpenPendingApprovalModal(false);
    onClose();
  };

  return (
    <>
      <FullPageModal
        isOpen={isOpen}
        onClose={handleModalClose}
        title={t("shopV2:orderCreation.editOrder")}
        hasBorder
      >
        {order && (
          <OrderForm
            user={order.user!}
            benefitSummary={benefitDefinition!}
            onCancelOrder={handleModalClose}
            editingOrder={order}
            onSubmitSuccess={handleOrderCreateSuccess}
            editingSection={editingSection}
            onFormChanged={setFormChangeStatus}
          />
        )}
        <Modal
          isOpen={isOpenConfirmationModal}
          onClose={handleCloseConfimationModal}
          onCancel={handleCloseConfimationModal}
          onConfirm={handleConfirmConfimationModal}
          title={t("shopV2:orderEditingModal.confirmationModal.title")}
          description={t(
            "shopV2:orderEditingModal.confirmationModal.description",
          )}
          confirmText={t(
            "shopV2:orderEditingModal.confirmationModal.confirmText",
          )}
          cancelText={t(
            "shopV2:orderEditingModal.confirmationModal.cancelText",
          )}
          confirmButtonVariant="primaryDanger"
        />
      </FullPageModal>
      <Modal
        isOpen={isOpenPendingApprovalModal}
        onClose={handleCloseConfimationModal}
        onConfirm={handleConfirmConfimationModal}
        title={t("shopV2:orderEditingModal.pendingApprovalModal.title")}
        description={t(
          "shopV2:orderEditingModal.pendingApprovalModal.description",
        )}
        confirmText={t("commonV2:gotIt")}
      />
    </>
  );
}
