import { sortBy } from "lodash-es";
import { useTranslation } from "react-i18next";

import {
  BenefitDefinitionSummaryRead,
  Currency,
  UserSummaryRead,
} from "@vapaus/api-codegen";
import { useCurrencyFormat } from "@vapaus/i18n";
import {
  Flex,
  HorizontalLine,
  Modal,
  RowItem,
  Typography,
} from "@vapaus/ui-v2";

import { useGetBenefitDefinition } from "../../../api/benefitDefinition";
import { getMonthlyPaymentLabel } from "../../../utils/getMonthlyPaymentLabel";

type BenefitRequirementModal = {
  user?: UserSummaryRead;
  selectedActivation?: BenefitDefinitionSummaryRead;
  isOpen: boolean;
  onClose: () => void;
  onConfirmOrderCreation: () => void;
};

export function BenefitRequirementModal({
  user,
  selectedActivation,
  isOpen,
  onClose,
  onConfirmOrderCreation,
}: BenefitRequirementModal) {
  const { t } = useTranslation();
  const formatCurrency = useCurrencyFormat(
    selectedActivation?.currency ?? Currency.Eur,
  );
  const { data: benefitDefinition } = useGetBenefitDefinition(
    selectedActivation?.id,
  );

  const fixedValues = sortBy(benefitDefinition?.fixedMonthlyTaxableValues);

  const monthlyPaymentLabel = getMonthlyPaymentLabel(t, benefitDefinition);

  const monthlyPaymentValue = fixedValues?.length
    ? `${formatCurrency(fixedValues[0])}-${formatCurrency(
        fixedValues[fixedValues.length - 1],
      )}`
    : `${formatCurrency(
        benefitDefinition?.minimumMonthlyTaxableValue,
      )} - ${formatCurrency(benefitDefinition?.maximumMonthlyTaxableValue)}`;

  const leasingPeriodsText = t(
    "shopV2:orderCreation.requirementModal.leasingPeriodMonths",
    {
      from: benefitDefinition?.minimumLeasingPeriod,
      to: benefitDefinition?.maximumLeasingPeriod,
    },
  );

  const translatedAllowed = t("shopV2:orderCreation.requirementModal.allowed");
  const translatedNotAllowed = t(
    "shopV2:orderCreation.requirementModal.notAllowed",
  );

  const isDownPaymentAllowed = benefitDefinition?.isDownpaymentAllowed;

  const downPaymentText = isDownPaymentAllowed
    ? translatedAllowed
    : translatedNotAllowed;

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      onCancel={onClose}
      onConfirm={onConfirmOrderCreation}
      confirmText={t("shopV2:orderCreation.requirementModal.createOrder")}
      cancelText={t("commonV2:cancel")}
      disableConfirm={!benefitDefinition}
    >
      <Flex direction="column" gap="none">
        <Typography variant="h2" align="center" marginBottom="xxs">
          {user?.fullName}
        </Typography>
        <Typography align="center" marginBottom="xxs">
          {user?.email}
        </Typography>
        <Typography weight="bold" align="center" marginBottom="md">
          {benefitDefinition?.organisation.name} · {benefitDefinition?.name}
        </Typography>
        <Typography align="center">
          {t("shopV2:orderCreation.requirementModal.description")}
        </Typography>
        <HorizontalLine />
        <Flex direction="column" gap="xs">
          <RowItem
            label={t("shopV2:orderCreation.requirementModal.maxPackagePrice")}
            value={formatCurrency(
              benefitDefinition?.maximumTaxablePackagePrice,
            )}
          />
          <RowItem
            label={t("shopV2:orderCreation.requirementModal.downPayment")}
            value={downPaymentText}
          />
          {isDownPaymentAllowed && (
            <RowItem
              label={t(
                "shopV2:orderCreation.requirementModal.maxPriceWithDownPayment",
              )}
              value={formatCurrency(
                benefitDefinition?.maximumTotalPackagePrice,
              )}
            />
          )}
          <RowItem
            label={t("shopV2:orderCreation.requirementModal.leasingPeriod")}
            value={leasingPeriodsText}
          />
          <RowItem label={monthlyPaymentLabel} value={monthlyPaymentValue} />
        </Flex>
      </Flex>
    </Modal>
  );
}
