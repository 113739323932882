/* tslint:disable */

/* eslint-disable */

/**
 * Vapaus
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { mapValues } from "../runtime";
import type { BikeSummaryRead } from "./BikeSummaryRead";
import {
  BikeSummaryReadFromJSON,
  BikeSummaryReadFromJSONTyped,
  BikeSummaryReadToJSON,
  BikeSummaryReadToJSONTyped,
} from "./BikeSummaryRead";
import type { Currency } from "./Currency";
import {
  CurrencyFromJSON,
  CurrencyFromJSONTyped,
  CurrencyToJSON,
  CurrencyToJSONTyped,
} from "./Currency";
import type { ShopSummaryRead } from "./ShopSummaryRead";
import {
  ShopSummaryReadFromJSON,
  ShopSummaryReadFromJSONTyped,
  ShopSummaryReadToJSON,
  ShopSummaryReadToJSONTyped,
} from "./ShopSummaryRead";
import type { UserSummaryRead } from "./UserSummaryRead";
import {
  UserSummaryReadFromJSON,
  UserSummaryReadFromJSONTyped,
  UserSummaryReadToJSON,
  UserSummaryReadToJSONTyped,
} from "./UserSummaryRead";
import type { WorkTypes } from "./WorkTypes";
import {
  WorkTypesFromJSON,
  WorkTypesFromJSONTyped,
  WorkTypesToJSON,
  WorkTypesToJSONTyped,
} from "./WorkTypes";

/**
 *
 * @export
 * @interface ShopMaintenanceEventRead
 */
export interface ShopMaintenanceEventRead {
  /**
   *
   * @type {number}
   * @memberof ShopMaintenanceEventRead
   */
  partsPrice: number;
  /**
   *
   * @type {number}
   * @memberof ShopMaintenanceEventRead
   */
  workPrice: number;
  /**
   *
   * @type {WorkTypes}
   * @memberof ShopMaintenanceEventRead
   */
  workType: WorkTypes;
  /**
   *
   * @type {string}
   * @memberof ShopMaintenanceEventRead
   */
  workDescription: string;
  /**
   *
   * @type {Date}
   * @memberof ShopMaintenanceEventRead
   */
  workDate: Date;
  /**
   *
   * @type {string}
   * @memberof ShopMaintenanceEventRead
   */
  id: string;
  /**
   *
   * @type {Currency}
   * @memberof ShopMaintenanceEventRead
   */
  currency: Currency;
  /**
   *
   * @type {Date}
   * @memberof ShopMaintenanceEventRead
   */
  createdAt?: Date;
  /**
   *
   * @type {string}
   * @memberof ShopMaintenanceEventRead
   */
  bikeId?: string;
  /**
   *
   * @type {string}
   * @memberof ShopMaintenanceEventRead
   */
  bikeBenefitContractId?: string;
  /**
   *
   * @type {string}
   * @memberof ShopMaintenanceEventRead
   */
  shopId: string;
  /**
   *
   * @type {string}
   * @memberof ShopMaintenanceEventRead
   */
  vapausCode?: string;
  /**
   *
   * @type {number}
   * @memberof ShopMaintenanceEventRead
   */
  totalPrice: number;
  /**
   *
   * @type {boolean}
   * @memberof ShopMaintenanceEventRead
   */
  isBillable: boolean;
  /**
   *
   * @type {number}
   * @memberof ShopMaintenanceEventRead
   */
  partsVatRate: number;
  /**
   *
   * @type {number}
   * @memberof ShopMaintenanceEventRead
   */
  workVatRate: number;
  /**
   *
   * @type {BikeSummaryRead}
   * @memberof ShopMaintenanceEventRead
   */
  bike: BikeSummaryRead;
  /**
   *
   * @type {UserSummaryRead}
   * @memberof ShopMaintenanceEventRead
   */
  user?: UserSummaryRead;
  /**
   *
   * @type {ShopSummaryRead}
   * @memberof ShopMaintenanceEventRead
   */
  shop: ShopSummaryRead;
}

/**
 * Check if a given object implements the ShopMaintenanceEventRead interface.
 */
export function instanceOfShopMaintenanceEventRead(
  value: object,
): value is ShopMaintenanceEventRead {
  if (!("partsPrice" in value) || value["partsPrice"] === undefined)
    return false;
  if (!("workPrice" in value) || value["workPrice"] === undefined) return false;
  if (!("workType" in value) || value["workType"] === undefined) return false;
  if (!("workDescription" in value) || value["workDescription"] === undefined)
    return false;
  if (!("workDate" in value) || value["workDate"] === undefined) return false;
  if (!("id" in value) || value["id"] === undefined) return false;
  if (!("currency" in value) || value["currency"] === undefined) return false;
  if (!("shopId" in value) || value["shopId"] === undefined) return false;
  if (!("totalPrice" in value) || value["totalPrice"] === undefined)
    return false;
  if (!("isBillable" in value) || value["isBillable"] === undefined)
    return false;
  if (!("partsVatRate" in value) || value["partsVatRate"] === undefined)
    return false;
  if (!("workVatRate" in value) || value["workVatRate"] === undefined)
    return false;
  if (!("bike" in value) || value["bike"] === undefined) return false;
  if (!("shop" in value) || value["shop"] === undefined) return false;
  return true;
}

export function ShopMaintenanceEventReadFromJSON(
  json: any,
): ShopMaintenanceEventRead {
  return ShopMaintenanceEventReadFromJSONTyped(json, false);
}

export function ShopMaintenanceEventReadFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): ShopMaintenanceEventRead {
  if (json == null) {
    return json;
  }
  return {
    partsPrice: json["parts_price"],
    workPrice: json["work_price"],
    workType: WorkTypesFromJSON(json["work_type"]),
    workDescription: json["work_description"],
    workDate: new Date(json["work_date"]),
    id: json["id"],
    currency: CurrencyFromJSON(json["currency"]),
    createdAt:
      json["created_at"] == null ? undefined : new Date(json["created_at"]),
    bikeId: json["bike_id"] == null ? undefined : json["bike_id"],
    bikeBenefitContractId:
      json["bike_benefit_contract_id"] == null
        ? undefined
        : json["bike_benefit_contract_id"],
    shopId: json["shop_id"],
    vapausCode: json["vapaus_code"] == null ? undefined : json["vapaus_code"],
    totalPrice: json["total_price"],
    isBillable: json["is_billable"],
    partsVatRate: json["parts_vat_rate"],
    workVatRate: json["work_vat_rate"],
    bike: BikeSummaryReadFromJSON(json["bike"]),
    user:
      json["user"] == null ? undefined : UserSummaryReadFromJSON(json["user"]),
    shop: ShopSummaryReadFromJSON(json["shop"]),
  };
}

export function ShopMaintenanceEventReadToJSON(
  json: any,
): ShopMaintenanceEventRead {
  return ShopMaintenanceEventReadToJSONTyped(json, false);
}

export function ShopMaintenanceEventReadToJSONTyped(
  value?: ShopMaintenanceEventRead | null,
  ignoreDiscriminator: boolean = false,
): any {
  if (value == null) {
    return value;
  }

  return {
    parts_price: value["partsPrice"],
    work_price: value["workPrice"],
    work_type: WorkTypesToJSON(value["workType"]),
    work_description: value["workDescription"],
    work_date: value["workDate"].toISOString().substring(0, 10),
    id: value["id"],
    currency: CurrencyToJSON(value["currency"]),
    created_at:
      value["createdAt"] == null ? undefined : value["createdAt"].toISOString(),
    bike_id: value["bikeId"],
    bike_benefit_contract_id: value["bikeBenefitContractId"],
    shop_id: value["shopId"],
    vapaus_code: value["vapausCode"],
    total_price: value["totalPrice"],
    is_billable: value["isBillable"],
    parts_vat_rate: value["partsVatRate"],
    work_vat_rate: value["workVatRate"],
    bike: BikeSummaryReadToJSON(value["bike"]),
    user: UserSummaryReadToJSON(value["user"]),
    shop: ShopSummaryReadToJSON(value["shop"]),
  };
}
