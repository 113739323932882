import { useTranslation } from "react-i18next";

import { SupportedOrganisationCountries } from "@vapaus/api-codegen";
import { useCurrencyFormat } from "@vapaus/i18n";
import { useSwedenOrderBenefitValuesCalculation } from "@vapaus/shared-api";
import {
  Flex,
  HorizontalLine,
  Paper,
  RowItem,
  Typography,
} from "@vapaus/ui-v2";

import { getMonthlyPaymentLabel } from "../../../../utils/getMonthlyPaymentLabel";
import { BaseOrderViewProps } from "../../type";
import { useOrderSummary } from "../hooks/useOrderSummary";

export function OrderCustomerDetails({
  order,
  benefitDefinition,
}: BaseOrderViewProps) {
  const { t } = useTranslation();
  const formatCurrency = useCurrencyFormat(order.currency);
  const { monthlyTaxableValue } = useOrderSummary(order, benefitDefinition);
  const monthlyPaymentLabel = getMonthlyPaymentLabel(t, benefitDefinition);
  const isBenefitSwedish =
    benefitDefinition?.organisation.country ===
    SupportedOrganisationCountries.Se;

  const { data: swedenSuggestedBenefitValues } =
    useSwedenOrderBenefitValuesCalculation(order, benefitDefinition);

  return (
    <Paper shadow="md">
      <Flex direction="column" gap="md">
        <Flex direction="column" gap="xxs">
          <Typography variant="h2" align="center" marginBottom="none">
            {order.user?.fullName}
          </Typography>
          <Typography weight="bold" align="center" marginBottom="none">
            {benefitDefinition?.organisation.name} · {benefitDefinition?.name}
          </Typography>
          <Typography align="center">{order.user?.email}</Typography>
        </Flex>
        <Flex direction="column">
          <RowItem
            label={t("shopV2:orderViewPage.customerDetails.vapausCode")}
            value={order.vapausCode}
          />
          <RowItem
            label={t("shopV2:orderViewPage.customerDetails.frameNumber")}
            value={order.bike.serialNumber || "-"}
          />
          <RowItem
            label={t("shopV2:orderViewPage.customerDetails.orderNumber")}
            value={order.orderNumber || "-"}
          />
          <HorizontalLine marginBottom="xs" marginTop="xs" />
          {isBenefitSwedish ? (
            <>
              <RowItem
                label={t(
                  "shopV2:orderViewPage.customerDetails.sweden.grossSalaryDeduction",
                )}
                value={formatCurrency(
                  swedenSuggestedBenefitValues?.suggestedSalaryDeduction,
                )}
              />
              <RowItem
                label={t(
                  "shopV2:orderViewPage.customerDetails.sweden.benefitValue",
                )}
                value={formatCurrency(
                  swedenSuggestedBenefitValues?.monthlyBenefitValueAfterTaxDeduction,
                )}
              />
            </>
          ) : null}
          <RowItem
            label={monthlyPaymentLabel}
            value={formatCurrency(monthlyTaxableValue)}
          />
          <RowItem
            label={t("shopV2:orderViewPage.customerDetails.maintenanceBudget")}
            value={t(
              "shopV2:orderViewPage.customerDetails.maintenanceBudgetValue",
              { value: formatCurrency(order.monthlyMaintenanceBudget) },
            )}
          />
          <RowItem
            label={t("shopV2:orderViewPage.customerDetails.leasingPeriod")}
            value={t(
              "shopV2:orderViewPage.customerDetails.leasingPeriodValue",
              { value: order.leasingPeriodMonths },
            )}
          />
        </Flex>
        {order.notes && (
          <Paper background="neutral.lighter" padding="sm">
            <Typography>{order.notes}</Typography>
          </Paper>
        )}
      </Flex>
    </Paper>
  );
}
