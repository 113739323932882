import { useTranslation } from "react-i18next";

import { useCurrencyFormat } from "@vapaus/i18n";
import { HorizontalLine, RowItem } from "@vapaus/ui-v2";

import { BaseOrderViewProps } from "../../type";
import { OrderSectionWithEdit } from "./OrderSectionWithEdit";

export function MaintenanceBudget({
  order,
  benefitDefinition,
}: BaseOrderViewProps) {
  const { t } = useTranslation();
  const { monthlyMaintenanceBudget, leasingPeriodMonths, currency } = order;
  const formatCurrency = useCurrencyFormat(currency);
  return (
    <OrderSectionWithEdit
      title={t("shopV2:orderViewPage.maintenanceBudget.title")}
      order={order}
      benefitDefinition={benefitDefinition}
      editingSection="MaintenanceBudget"
    >
      <RowItem
        label={t("shopV2:orderViewPage.maintenanceBudget.leasingPeriod")}
        value={t("shopV2:orderViewPage.summary.leasingPeriodMonths", {
          value: leasingPeriodMonths,
        })}
      />
      <RowItem
        label={t("shopV2:orderViewPage.maintenanceBudget.monthlyBudget")}
        value={formatCurrency(monthlyMaintenanceBudget)}
      />
      <HorizontalLine />
      <RowItem
        label={t("shopV2:orderViewPage.maintenanceBudget.totalBudget")}
        value={formatCurrency(monthlyMaintenanceBudget * leasingPeriodMonths)}
        valueIsBold
      />
    </OrderSectionWithEdit>
  );
}
