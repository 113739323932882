import { useTranslation } from "react-i18next";

import { useCurrencyFormat, usePercentFormat } from "@vapaus/i18n";
import { useEnumEntries } from "@vapaus/shared-api";
import { HorizontalLine, RowItem } from "@vapaus/ui-v2";

import { BaseOrderViewProps } from "../../type";
import { OrderSectionWithEdit } from "./OrderSectionWithEdit";

export function BikeInformation({
  order,
  benefitDefinition,
}: BaseOrderViewProps) {
  const { t } = useTranslation();
  const { bike, bikePrice, currency, discountBike, flatVatRate } = order;
  const formatCurrency = useCurrencyFormat(currency);
  const formatPercent = usePercentFormat();
  const { getEnumLabel: getBikeDriveTypeEnumLabel } =
    useEnumEntries("BikeDriveType");
  const { getEnumLabel: getBikeFrameTypeEnumLabel } =
    useEnumEntries("BikeFrameType");

  const discountedBikePrice = bikePrice * discountBike;
  return (
    <OrderSectionWithEdit
      title={t("shopV2:orderViewPage.bikeInformation.title")}
      order={order}
      benefitDefinition={benefitDefinition}
    >
      <RowItem
        label={t("shopV2:orderViewPage.bikeInformation.manufacturer")}
        value={bike.bikeBrand.name}
        justify="flex-start"
      />
      <RowItem
        label={t("shopV2:orderViewPage.bikeInformation.model")}
        value={bike.model}
        justify="flex-start"
      />
      <RowItem
        label={t("shopV2:orderViewPage.bikeInformation.driveType")}
        value={getBikeDriveTypeEnumLabel(bike.driveType)}
        justify="flex-start"
      />
      <RowItem
        label={t("shopV2:orderViewPage.bikeInformation.frameType")}
        value={getBikeFrameTypeEnumLabel(bike.frameType)}
        justify="flex-start"
      />
      <RowItem
        label={t("shopV2:orderViewPage.bikeInformation.size")}
        value={bike.size}
        justify="flex-start"
      />
      <RowItem
        label={t("shopV2:orderViewPage.bikeInformation.color")}
        value={bike.colour}
        justify="flex-start"
      />
      <RowItem
        label={t("shopV2:orderViewPage.bikeInformation.frameNumber")}
        value={bike.serialNumber}
        justify="flex-start"
      />
      <HorizontalLine />
      <RowItem
        label={t("shopV2:orderViewPage.priceWithVat", {
          value: formatPercent(flatVatRate),
        })}
        value={formatCurrency(bikePrice)}
        valueIsBold
      />
      <RowItem
        label={t("shopV2:orderViewPage.bikeInformation.discountedPrice", {
          value: formatPercent(discountBike),
        })}
        value={formatCurrency(bikePrice - discountedBikePrice)}
        typoVariant="bodySm"
        labelIsBold={false}
      />
    </OrderSectionWithEdit>
  );
}
