/* tslint:disable */

/* eslint-disable */

/**
 * Vapaus
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { mapValues } from "../runtime";
import type { AdditionalServiceType } from "./AdditionalServiceType";
import {
  AdditionalServiceTypeFromJSON,
  AdditionalServiceTypeFromJSONTyped,
  AdditionalServiceTypeToJSON,
  AdditionalServiceTypeToJSONTyped,
} from "./AdditionalServiceType";

/**
 *
 * @export
 * @interface BikeBenefitOrderAdditionalServiceWrite
 */
export interface BikeBenefitOrderAdditionalServiceWrite {
  /**
   * Leave empty in case of creating an Additional Service
   * @type {string}
   * @memberof BikeBenefitOrderAdditionalServiceWrite
   */
  id?: string;
  /**
   *
   * @type {AdditionalServiceType}
   * @memberof BikeBenefitOrderAdditionalServiceWrite
   */
  serviceType: AdditionalServiceType;
  /**
   *
   * @type {string}
   * @memberof BikeBenefitOrderAdditionalServiceWrite
   */
  description?: string;
  /**
   *
   * @type {number}
   * @memberof BikeBenefitOrderAdditionalServiceWrite
   */
  priceGross: number;
  /**
   *
   * @type {number}
   * @memberof BikeBenefitOrderAdditionalServiceWrite
   */
  vatRate: number;
}

/**
 * Check if a given object implements the BikeBenefitOrderAdditionalServiceWrite interface.
 */
export function instanceOfBikeBenefitOrderAdditionalServiceWrite(
  value: object,
): value is BikeBenefitOrderAdditionalServiceWrite {
  if (!("serviceType" in value) || value["serviceType"] === undefined)
    return false;
  if (!("priceGross" in value) || value["priceGross"] === undefined)
    return false;
  if (!("vatRate" in value) || value["vatRate"] === undefined) return false;
  return true;
}

export function BikeBenefitOrderAdditionalServiceWriteFromJSON(
  json: any,
): BikeBenefitOrderAdditionalServiceWrite {
  return BikeBenefitOrderAdditionalServiceWriteFromJSONTyped(json, false);
}

export function BikeBenefitOrderAdditionalServiceWriteFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): BikeBenefitOrderAdditionalServiceWrite {
  if (json == null) {
    return json;
  }
  return {
    id: json["id"] == null ? undefined : json["id"],
    serviceType: AdditionalServiceTypeFromJSON(json["service_type"]),
    description: json["description"] == null ? undefined : json["description"],
    priceGross: json["price_gross"],
    vatRate: json["vat_rate"],
  };
}

export function BikeBenefitOrderAdditionalServiceWriteToJSON(
  json: any,
): BikeBenefitOrderAdditionalServiceWrite {
  return BikeBenefitOrderAdditionalServiceWriteToJSONTyped(json, false);
}

export function BikeBenefitOrderAdditionalServiceWriteToJSONTyped(
  value?: BikeBenefitOrderAdditionalServiceWrite | null,
  ignoreDiscriminator: boolean = false,
): any {
  if (value == null) {
    return value;
  }

  return {
    id: value["id"],
    service_type: AdditionalServiceTypeToJSON(value["serviceType"]),
    description: value["description"],
    price_gross: value["priceGross"],
    vat_rate: value["vatRate"],
  };
}
