/* tslint:disable */

/* eslint-disable */

/**
 * Vapaus
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { mapValues } from "../runtime";
import type { BikeBrand } from "./BikeBrand";
import {
  BikeBrandFromJSON,
  BikeBrandFromJSONTyped,
  BikeBrandToJSON,
  BikeBrandToJSONTyped,
} from "./BikeBrand";
import type { BikeCondition } from "./BikeCondition";
import {
  BikeConditionFromJSON,
  BikeConditionFromJSONTyped,
  BikeConditionToJSON,
  BikeConditionToJSONTyped,
} from "./BikeCondition";
import type { BikeDriveType } from "./BikeDriveType";
import {
  BikeDriveTypeFromJSON,
  BikeDriveTypeFromJSONTyped,
  BikeDriveTypeToJSON,
  BikeDriveTypeToJSONTyped,
} from "./BikeDriveType";
import type { BikeFrameType } from "./BikeFrameType";
import {
  BikeFrameTypeFromJSON,
  BikeFrameTypeFromJSONTyped,
  BikeFrameTypeToJSON,
  BikeFrameTypeToJSONTyped,
} from "./BikeFrameType";
import type { Currency } from "./Currency";
import {
  CurrencyFromJSON,
  CurrencyFromJSONTyped,
  CurrencyToJSON,
  CurrencyToJSONTyped,
} from "./Currency";

/**
 *
 * @export
 * @interface BikeSummaryRead
 */
export interface BikeSummaryRead {
  /**
   *
   * @type {string}
   * @memberof BikeSummaryRead
   */
  id: string;
  /**
   *
   * @type {string}
   * @memberof BikeSummaryRead
   */
  serialNumber: string;
  /**
   *
   * @type {string}
   * @memberof BikeSummaryRead
   */
  bikeBrandId: string;
  /**
   *
   * @type {string}
   * @memberof BikeSummaryRead
   */
  model: string;
  /**
   *
   * @type {BikeDriveType}
   * @memberof BikeSummaryRead
   */
  driveType?: BikeDriveType;
  /**
   *
   * @type {BikeFrameType}
   * @memberof BikeSummaryRead
   */
  frameType?: BikeFrameType;
  /**
   *
   * @type {string}
   * @memberof BikeSummaryRead
   */
  size: string;
  /**
   *
   * @type {string}
   * @memberof BikeSummaryRead
   */
  colour: string;
  /**
   *
   * @type {number}
   * @memberof BikeSummaryRead
   */
  purchasePrice: number;
  /**
   *
   * @type {Currency}
   * @memberof BikeSummaryRead
   */
  purchaseCurrency: Currency;
  /**
   *
   * @type {number}
   * @memberof BikeSummaryRead
   */
  purchaseVatRate: number;
  /**
   *
   * @type {BikeCondition}
   * @memberof BikeSummaryRead
   */
  condition: BikeCondition;
  /**
   *
   * @type {BikeBrand}
   * @memberof BikeSummaryRead
   */
  bikeBrand: BikeBrand;
}

/**
 * Check if a given object implements the BikeSummaryRead interface.
 */
export function instanceOfBikeSummaryRead(
  value: object,
): value is BikeSummaryRead {
  if (!("id" in value) || value["id"] === undefined) return false;
  if (!("serialNumber" in value) || value["serialNumber"] === undefined)
    return false;
  if (!("bikeBrandId" in value) || value["bikeBrandId"] === undefined)
    return false;
  if (!("model" in value) || value["model"] === undefined) return false;
  if (!("size" in value) || value["size"] === undefined) return false;
  if (!("colour" in value) || value["colour"] === undefined) return false;
  if (!("purchasePrice" in value) || value["purchasePrice"] === undefined)
    return false;
  if (!("purchaseCurrency" in value) || value["purchaseCurrency"] === undefined)
    return false;
  if (!("purchaseVatRate" in value) || value["purchaseVatRate"] === undefined)
    return false;
  if (!("condition" in value) || value["condition"] === undefined) return false;
  if (!("bikeBrand" in value) || value["bikeBrand"] === undefined) return false;
  return true;
}

export function BikeSummaryReadFromJSON(json: any): BikeSummaryRead {
  return BikeSummaryReadFromJSONTyped(json, false);
}

export function BikeSummaryReadFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): BikeSummaryRead {
  if (json == null) {
    return json;
  }
  return {
    id: json["id"],
    serialNumber: json["serial_number"],
    bikeBrandId: json["bike_brand_id"],
    model: json["model"],
    driveType:
      json["drive_type"] == null
        ? undefined
        : BikeDriveTypeFromJSON(json["drive_type"]),
    frameType:
      json["frame_type"] == null
        ? undefined
        : BikeFrameTypeFromJSON(json["frame_type"]),
    size: json["size"],
    colour: json["colour"],
    purchasePrice: json["purchase_price"],
    purchaseCurrency: CurrencyFromJSON(json["purchase_currency"]),
    purchaseVatRate: json["purchase_vat_rate"],
    condition: BikeConditionFromJSON(json["condition"]),
    bikeBrand: BikeBrandFromJSON(json["bike_brand"]),
  };
}

export function BikeSummaryReadToJSON(json: any): BikeSummaryRead {
  return BikeSummaryReadToJSONTyped(json, false);
}

export function BikeSummaryReadToJSONTyped(
  value?: BikeSummaryRead | null,
  ignoreDiscriminator: boolean = false,
): any {
  if (value == null) {
    return value;
  }

  return {
    id: value["id"],
    serial_number: value["serialNumber"],
    bike_brand_id: value["bikeBrandId"],
    model: value["model"],
    drive_type: BikeDriveTypeToJSON(value["driveType"]),
    frame_type: BikeFrameTypeToJSON(value["frameType"]),
    size: value["size"],
    colour: value["colour"],
    purchase_price: value["purchasePrice"],
    purchase_currency: CurrencyToJSON(value["purchaseCurrency"]),
    purchase_vat_rate: value["purchaseVatRate"],
    condition: BikeConditionToJSON(value["condition"]),
    bike_brand: BikeBrandToJSON(value["bikeBrand"]),
  };
}
