/* tslint:disable */
/* eslint-disable */
/**
 * Vapaus
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * An enumeration.
 * @export
 */
export const SaleInvoiceLineType = {
  DownPayment: "down_payment",
  DownPaymentInsurance: "down_payment_insurance",
  CancellationCoverage: "cancellation_coverage",
  ExtendedWarranty: "extended_warranty",
  InsuranceDeductible: "insurance_deductible",
  RedemptionFee: "redemption_fee",
  RedemptionFeeAccessoriesOnly: "redemption_fee_accessories_only",
  ReturnFee: "return_fee",
  Maintenance: "maintenance",
  Other: "other",
  Refund: "refund",
  OverspentMaintenanceBudget: "overspent_maintenance_budget",
  ExcessMonthlyPayment: "excess_monthly_payment",
  Delivery: "delivery",
  LateFees: "late_fees",
} as const;
export type SaleInvoiceLineType =
  (typeof SaleInvoiceLineType)[keyof typeof SaleInvoiceLineType];

export function instanceOfSaleInvoiceLineType(value: any): boolean {
  for (const key in SaleInvoiceLineType) {
    if (Object.prototype.hasOwnProperty.call(SaleInvoiceLineType, key)) {
      if (
        SaleInvoiceLineType[key as keyof typeof SaleInvoiceLineType] === value
      ) {
        return true;
      }
    }
  }
  return false;
}

export function SaleInvoiceLineTypeFromJSON(json: any): SaleInvoiceLineType {
  return SaleInvoiceLineTypeFromJSONTyped(json, false);
}

export function SaleInvoiceLineTypeFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): SaleInvoiceLineType {
  return json as SaleInvoiceLineType;
}

export function SaleInvoiceLineTypeToJSON(
  value?: SaleInvoiceLineType | null,
): any {
  return value as any;
}

export function SaleInvoiceLineTypeToJSONTyped(
  value: any,
  ignoreDiscriminator: boolean,
): SaleInvoiceLineType {
  return value as SaleInvoiceLineType;
}
