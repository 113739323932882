import { Dispatch, SetStateAction, useEffect, useRef } from "react";

import styled from "styled-components";

import {
  BenefitDefinitionSummaryRead,
  BikeBenefitOrderExtraRead,
  BikeBenefitOrderRead,
  UserSummaryRead,
} from "@vapaus/api-codegen";
import { Form } from "@vapaus/form";
import {
  ProductTypes,
  SupportedOrganisationCountries,
} from "@vapaus/generated";
import { Flex } from "@vapaus/ui-v2";

import {
  AdditionalServicesForm,
  BikeAccessoriesForm,
  BikeDataCheckResultModal,
  BikeInformationForm,
  CalculationBar,
  DeliveryForm,
  FormSectionType,
  LeasingContractForm,
  MaintenanceBudgetForm,
  OrderFormSummary,
} from "./FormSections";
import { useOrderForm } from "./hooks";
import { getIsFormDirty } from "./utils/getIsFormDirty";

type OrderFormProps = {
  onCancelOrder: () => void;
  onSubmitSuccess: (order: BikeBenefitOrderRead) => void;
  user: UserSummaryRead;
  benefitSummary: BenefitDefinitionSummaryRead;
  editingOrder?: BikeBenefitOrderExtraRead;
  editingSection?: FormSectionType;
  onFormChanged?: Dispatch<SetStateAction<boolean>>;
};

export function OrderForm({
  onCancelOrder,
  user,
  benefitSummary,
  editingOrder,
  onSubmitSuccess,
  editingSection,
  onFormChanged,
}: OrderFormProps) {
  const {
    formMethods,
    mutation,
    formSummary,
    benefitDefinition,
    getProductVatRate,
    bikeDataCheckResult,
    isBikeDataCheckResultModalOpen,
    closeBikeDataCheckResultModal,
    confirmBikeDataCheckResultModal,
    isSubmitEnabled,
  } = useOrderForm(user, benefitSummary, onSubmitSuccess, editingOrder);

  const accessoriesSectionRef = useRef<HTMLDivElement>(null);
  const additionalServiceSectionRef = useRef<HTMLDivElement>(null);
  const deliverySectionRef = useRef<HTMLDivElement>(null);
  const maintenanceBudgetSectionRef = useRef<HTMLDivElement>(null);

  const scrollToSection = (element: HTMLDivElement | null) => {
    if (element)
      element.scrollIntoView({ behavior: "smooth", block: "center" });
  };

  const hasDirtyFields = getIsFormDirty(formMethods.formState.dirtyFields);
  useEffect(() => {
    onFormChanged?.(hasDirtyFields);
  }, [hasDirtyFields, onFormChanged]);

  useEffect(() => {
    switch (editingSection) {
      case "Accessories":
        scrollToSection(accessoriesSectionRef.current);
        break;
      case "AdditionalServices":
        scrollToSection(additionalServiceSectionRef.current);
        break;
      case "Delivery":
        scrollToSection(deliverySectionRef.current);
        break;
      case "MaintenanceBudget":
        scrollToSection(maintenanceBudgetSectionRef.current);
        break;
    }
  }, [editingSection]);

  return (
    <Form formMethods={formMethods}>
      {benefitDefinition && (
        <CalculationBar
          user={user}
          hasFixedTaxableValue={formSummary.hasFixedTaxableValue}
          orderTotal={formSummary.orderTotal}
          benefitDefinition={benefitDefinition}
        />
      )}
      <StyledRoot
        padding="xl"
        xsPadding="md"
        smPadding="lg"
        direction="column"
        gap="xl"
        xsGap="md"
      >
        <BikeInformationForm
          currency={benefitDefinition?.currency}
          vatRate={getProductVatRate(ProductTypes.BIKE)}
          bikeDataCheckResult={bikeDataCheckResult}
        />
        <div ref={accessoriesSectionRef}>
          <BikeAccessoriesForm
            currency={benefitDefinition?.currency}
            vatRate={getProductVatRate(ProductTypes.ACCESSORY)}
            country={
              (benefitDefinition?.organisation.country ||
                SupportedOrganisationCountries.FI) as SupportedOrganisationCountries
            }
          />
        </div>
        <div ref={additionalServiceSectionRef}>
          <AdditionalServicesForm
            currency={benefitDefinition?.currency}
            vatRate={getProductVatRate(ProductTypes.ADDITIONAL_SERVICES)}
          />
        </div>
        <div ref={deliverySectionRef}>
          <DeliveryForm
            currency={benefitDefinition?.currency}
            vatRate={getProductVatRate(ProductTypes.DELIVERY)}
            editingOrder={editingOrder}
          />
        </div>
        <div ref={maintenanceBudgetSectionRef}>
          <MaintenanceBudgetForm currency={benefitDefinition?.currency} />
        </div>
        <LeasingContractForm
          benefitDefinition={benefitDefinition}
          formSummary={formSummary}
          currency={benefitDefinition?.currency}
        />
        <OrderFormSummary
          onCancelOrder={onCancelOrder}
          formSummary={formSummary}
          isSubmitting={mutation.isLoading}
          isSubmitEnabled={isSubmitEnabled}
          currency={benefitDefinition?.currency}
          editingOrder={!!editingOrder}
        />
        <BikeDataCheckResultModal
          isOpen={isBikeDataCheckResultModalOpen}
          onClose={closeBikeDataCheckResultModal}
          onConfirm={confirmBikeDataCheckResultModal}
          bikeDataCheckResult={bikeDataCheckResult}
        />
      </StyledRoot>
    </Form>
  );
}

const StyledRoot = styled(Flex)`
  max-width: ${({ theme }) => `calc(800px + 2 * ${theme.spacing.xl})`};
  width: 100%;
  margin: auto;
`;
