/* tslint:disable */

/* eslint-disable */

/**
 * Vapaus
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { mapValues } from "../runtime";
import type { BikeBenefitContractRead } from "./BikeBenefitContractRead";
import {
  BikeBenefitContractReadFromJSON,
  BikeBenefitContractReadFromJSONTyped,
  BikeBenefitContractReadToJSON,
  BikeBenefitContractReadToJSONTyped,
} from "./BikeBenefitContractRead";

/**
 *
 * @export
 * @interface PaginatedBikeBenefitContractOut
 */
export interface PaginatedBikeBenefitContractOut {
  /**
   *
   * @type {number}
   * @memberof PaginatedBikeBenefitContractOut
   */
  total: number;
  /**
   *
   * @type {Array<BikeBenefitContractRead>}
   * @memberof PaginatedBikeBenefitContractOut
   */
  items: Array<BikeBenefitContractRead>;
}

/**
 * Check if a given object implements the PaginatedBikeBenefitContractOut interface.
 */
export function instanceOfPaginatedBikeBenefitContractOut(
  value: object,
): value is PaginatedBikeBenefitContractOut {
  if (!("total" in value) || value["total"] === undefined) return false;
  if (!("items" in value) || value["items"] === undefined) return false;
  return true;
}

export function PaginatedBikeBenefitContractOutFromJSON(
  json: any,
): PaginatedBikeBenefitContractOut {
  return PaginatedBikeBenefitContractOutFromJSONTyped(json, false);
}

export function PaginatedBikeBenefitContractOutFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): PaginatedBikeBenefitContractOut {
  if (json == null) {
    return json;
  }
  return {
    total: json["total"],
    items: (json["items"] as Array<any>).map(BikeBenefitContractReadFromJSON),
  };
}

export function PaginatedBikeBenefitContractOutToJSON(
  json: any,
): PaginatedBikeBenefitContractOut {
  return PaginatedBikeBenefitContractOutToJSONTyped(json, false);
}

export function PaginatedBikeBenefitContractOutToJSONTyped(
  value?: PaginatedBikeBenefitContractOut | null,
  ignoreDiscriminator: boolean = false,
): any {
  if (value == null) {
    return value;
  }

  return {
    total: value["total"],
    items: (value["items"] as Array<any>).map(BikeBenefitContractReadToJSON),
  };
}
