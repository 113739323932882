/* tslint:disable */
/* eslint-disable */
/**
 * Vapaus
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * An enumeration.
 * @export
 */
export const BikeBenefitOrderState = {
  Draft: "draft",
  ReadyForDelivery: "ready_for_delivery",
  WaitingForPayment: "waiting_for_payment",
  Finalised: "finalised",
  Cancelled: "cancelled",
} as const;
export type BikeBenefitOrderState =
  (typeof BikeBenefitOrderState)[keyof typeof BikeBenefitOrderState];

export function instanceOfBikeBenefitOrderState(value: any): boolean {
  for (const key in BikeBenefitOrderState) {
    if (Object.prototype.hasOwnProperty.call(BikeBenefitOrderState, key)) {
      if (
        BikeBenefitOrderState[key as keyof typeof BikeBenefitOrderState] ===
        value
      ) {
        return true;
      }
    }
  }
  return false;
}

export function BikeBenefitOrderStateFromJSON(
  json: any,
): BikeBenefitOrderState {
  return BikeBenefitOrderStateFromJSONTyped(json, false);
}

export function BikeBenefitOrderStateFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): BikeBenefitOrderState {
  return json as BikeBenefitOrderState;
}

export function BikeBenefitOrderStateToJSON(
  value?: BikeBenefitOrderState | null,
): any {
  return value as any;
}

export function BikeBenefitOrderStateToJSONTyped(
  value: any,
  ignoreDiscriminator: boolean,
): BikeBenefitOrderState {
  return value as BikeBenefitOrderState;
}
