/* tslint:disable */

/* eslint-disable */

/**
 * Vapaus
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { mapValues } from "../runtime";
import type { BikeDataCheckFieldResult } from "./BikeDataCheckFieldResult";
import {
  BikeDataCheckFieldResultFromJSON,
  BikeDataCheckFieldResultFromJSONTyped,
  BikeDataCheckFieldResultToJSON,
  BikeDataCheckFieldResultToJSONTyped,
} from "./BikeDataCheckFieldResult";

/**
 *
 * @export
 * @interface BikeDataCheckResult
 */
export interface BikeDataCheckResult {
  /**
   *
   * @type {BikeDataCheckFieldResult}
   * @memberof BikeDataCheckResult
   */
  serialNumber: BikeDataCheckFieldResult;
}

/**
 * Check if a given object implements the BikeDataCheckResult interface.
 */
export function instanceOfBikeDataCheckResult(
  value: object,
): value is BikeDataCheckResult {
  if (!("serialNumber" in value) || value["serialNumber"] === undefined)
    return false;
  return true;
}

export function BikeDataCheckResultFromJSON(json: any): BikeDataCheckResult {
  return BikeDataCheckResultFromJSONTyped(json, false);
}

export function BikeDataCheckResultFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): BikeDataCheckResult {
  if (json == null) {
    return json;
  }
  return {
    serialNumber: BikeDataCheckFieldResultFromJSON(json["serial_number"]),
  };
}

export function BikeDataCheckResultToJSON(json: any): BikeDataCheckResult {
  return BikeDataCheckResultToJSONTyped(json, false);
}

export function BikeDataCheckResultToJSONTyped(
  value?: BikeDataCheckResult | null,
  ignoreDiscriminator: boolean = false,
): any {
  if (value == null) {
    return value;
  }

  return {
    serial_number: BikeDataCheckFieldResultToJSON(value["serialNumber"]),
  };
}
