/* tslint:disable */

/* eslint-disable */

/**
 * Vapaus
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { mapValues } from "../runtime";
import type { Accessory } from "./Accessory";
import {
  AccessoryFromJSON,
  AccessoryFromJSONTyped,
  AccessoryToJSON,
  AccessoryToJSONTyped,
} from "./Accessory";
import type { AdditionalService } from "./AdditionalService";
import {
  AdditionalServiceFromJSON,
  AdditionalServiceFromJSONTyped,
  AdditionalServiceToJSON,
  AdditionalServiceToJSONTyped,
} from "./AdditionalService";
import type { BenefitCancellationCoverage } from "./BenefitCancellationCoverage";
import {
  BenefitCancellationCoverageFromJSON,
  BenefitCancellationCoverageFromJSONTyped,
  BenefitCancellationCoverageToJSON,
  BenefitCancellationCoverageToJSONTyped,
} from "./BenefitCancellationCoverage";
import type { BikeAdminRead } from "./BikeAdminRead";
import {
  BikeAdminReadFromJSON,
  BikeAdminReadFromJSONTyped,
  BikeAdminReadToJSON,
  BikeAdminReadToJSONTyped,
} from "./BikeAdminRead";
import type { BikeBenefitOrderCancellationReason } from "./BikeBenefitOrderCancellationReason";
import {
  BikeBenefitOrderCancellationReasonFromJSON,
  BikeBenefitOrderCancellationReasonFromJSONTyped,
  BikeBenefitOrderCancellationReasonToJSON,
  BikeBenefitOrderCancellationReasonToJSONTyped,
} from "./BikeBenefitOrderCancellationReason";
import type { BikeBenefitOrderDeliveryTransport } from "./BikeBenefitOrderDeliveryTransport";
import {
  BikeBenefitOrderDeliveryTransportFromJSON,
  BikeBenefitOrderDeliveryTransportFromJSONTyped,
  BikeBenefitOrderDeliveryTransportToJSON,
  BikeBenefitOrderDeliveryTransportToJSONTyped,
} from "./BikeBenefitOrderDeliveryTransport";
import type { BikeBenefitOrderState } from "./BikeBenefitOrderState";
import {
  BikeBenefitOrderStateFromJSON,
  BikeBenefitOrderStateFromJSONTyped,
  BikeBenefitOrderStateToJSON,
  BikeBenefitOrderStateToJSONTyped,
} from "./BikeBenefitOrderState";
import type { BikeBenefitOrderingMethod } from "./BikeBenefitOrderingMethod";
import {
  BikeBenefitOrderingMethodFromJSON,
  BikeBenefitOrderingMethodFromJSONTyped,
  BikeBenefitOrderingMethodToJSON,
  BikeBenefitOrderingMethodToJSONTyped,
} from "./BikeBenefitOrderingMethod";
import type { Currency } from "./Currency";
import {
  CurrencyFromJSON,
  CurrencyFromJSONTyped,
  CurrencyToJSON,
  CurrencyToJSONTyped,
} from "./Currency";
import type { UserRead } from "./UserRead";
import {
  UserReadFromJSON,
  UserReadFromJSONTyped,
  UserReadToJSON,
  UserReadToJSONTyped,
} from "./UserRead";

/**
 *
 * @export
 * @interface BikeBenefitOrderRead
 */
export interface BikeBenefitOrderRead {
  /**
   *
   * @type {string}
   * @memberof BikeBenefitOrderRead
   */
  vapausCode: string;
  /**
   *
   * @type {string}
   * @memberof BikeBenefitOrderRead
   */
  orderNumber?: string;
  /**
   *
   * @type {string}
   * @memberof BikeBenefitOrderRead
   */
  correctsBikeBenefitContractId?: string;
  /**
   *
   * @type {string}
   * @memberof BikeBenefitOrderRead
   */
  revisesBikeBenefitContractId?: string;
  /**
   *
   * @type {Date}
   * @memberof BikeBenefitOrderRead
   */
  revisionApplyDate?: Date;
  /**
   *
   * @type {string}
   * @memberof BikeBenefitOrderRead
   */
  legacyUserName?: string;
  /**
   *
   * @type {string}
   * @memberof BikeBenefitOrderRead
   */
  userId?: string;
  /**
   *
   * @type {boolean}
   * @memberof BikeBenefitOrderRead
   */
  isCancelled?: boolean;
  /**
   *
   * @type {BikeBenefitOrderCancellationReason}
   * @memberof BikeBenefitOrderRead
   */
  cancellationReason?: BikeBenefitOrderCancellationReason;
  /**
   *
   * @type {string}
   * @memberof BikeBenefitOrderRead
   */
  currentVersion?: string;
  /**
   *
   * @type {string}
   * @memberof BikeBenefitOrderRead
   */
  acceptedVersion?: string;
  /**
   *
   * @type {string}
   * @memberof BikeBenefitOrderRead
   */
  benefitDefinitionId: string;
  /**
   *
   * @type {string}
   * @memberof BikeBenefitOrderRead
   */
  benefitDefinitionTermsId: string;
  /**
   *
   * @type {number}
   * @memberof BikeBenefitOrderRead
   */
  leasingPeriodMonths: number;
  /**
   *
   * @type {string}
   * @memberof BikeBenefitOrderRead
   */
  shopId: string;
  /**
   *
   * @type {string}
   * @memberof BikeBenefitOrderRead
   */
  bikeId: string;
  /**
   *
   * @type {number}
   * @memberof BikeBenefitOrderRead
   */
  netBikePrice: number;
  /**
   *
   * @type {Currency}
   * @memberof BikeBenefitOrderRead
   */
  currency: Currency;
  /**
   *
   * @type {number}
   * @memberof BikeBenefitOrderRead
   */
  monthlyMaintenanceBudget: number;
  /**
   *
   * @type {number}
   * @memberof BikeBenefitOrderRead
   */
  flatVatRate: number;
  /**
   *
   * @type {boolean}
   * @memberof BikeBenefitOrderRead
   */
  hasExtendedWarranty: boolean;
  /**
   *
   * @type {number}
   * @memberof BikeBenefitOrderRead
   */
  fixedDownPaymentAmount?: number;
  /**
   *
   * @type {number}
   * @memberof BikeBenefitOrderRead
   */
  fixedMonthlyTaxableValue?: number;
  /**
   *
   * @type {number}
   * @memberof BikeBenefitOrderRead
   */
  netTaxablePackagePriceReduction?: number;
  /**
   *
   * @type {boolean}
   * @memberof BikeBenefitOrderRead
   */
  isDownPaymentInsured: boolean;
  /**
   *
   * @type {string}
   * @memberof BikeBenefitOrderRead
   */
  notes?: string;
  /**
   *
   * @type {boolean}
   * @memberof BikeBenefitOrderRead
   */
  isBillable?: boolean;
  /**
   *
   * @type {BenefitCancellationCoverage}
   * @memberof BikeBenefitOrderRead
   */
  cancellationCoverage?: BenefitCancellationCoverage;
  /**
   *
   * @type {BikeBenefitOrderingMethod}
   * @memberof BikeBenefitOrderRead
   */
  orderingMethod?: BikeBenefitOrderingMethod;
  /**
   *
   * @type {string}
   * @memberof BikeBenefitOrderRead
   */
  id: string;
  /**
   *
   * @type {Date}
   * @memberof BikeBenefitOrderRead
   */
  createdAt?: Date;
  /**
   *
   * @type {Date}
   * @memberof BikeBenefitOrderRead
   */
  updatedAt?: Date;
  /**
   *
   * @type {UserRead}
   * @memberof BikeBenefitOrderRead
   */
  user?: UserRead;
  /**
   *
   * @type {BikeAdminRead}
   * @memberof BikeBenefitOrderRead
   */
  bike: BikeAdminRead;
  /**
   *
   * @type {number}
   * @memberof BikeBenefitOrderRead
   */
  bikePrice: number;
  /**
   *
   * @type {Array<Accessory>}
   * @memberof BikeBenefitOrderRead
   */
  accessories: Array<Accessory>;
  /**
   *
   * @type {Array<AdditionalService>}
   * @memberof BikeBenefitOrderRead
   */
  additionalServices: Array<AdditionalService>;
  /**
   *
   * @type {BikeBenefitOrderState}
   * @memberof BikeBenefitOrderRead
   */
  state: BikeBenefitOrderState;
  /**
   *
   * @type {BikeBenefitOrderDeliveryTransport}
   * @memberof BikeBenefitOrderRead
   */
  deliveryTransport?: BikeBenefitOrderDeliveryTransport;
  /**
   *
   * @type {string}
   * @memberof BikeBenefitOrderRead
   */
  currentBikeBenefitContractId?: string;
  /**
   *
   * @type {Array<string>}
   * @memberof BikeBenefitOrderRead
   */
  bikeBenefitContractIds: Array<string>;
  /**
   *
   * @type {Date}
   * @memberof BikeBenefitOrderRead
   */
  deliveryDate?: Date;
  /**
   *
   * @type {string}
   * @memberof BikeBenefitOrderRead
   */
  revisesBikeBenefitContractVapausCode?: string;
  /**
   *
   * @type {string}
   * @memberof BikeBenefitOrderRead
   */
  correctsBikeBenefitContractVapausCode?: string;
  /**
   *
   * @type {Array<string>}
   * @memberof BikeBenefitOrderRead
   */
  revisedByBikeBenefitOrderVapausCodes: Array<string>;
  /**
   *
   * @type {Array<string>}
   * @memberof BikeBenefitOrderRead
   */
  correctedByBikeBenefitOrderVapausCodes: Array<string>;
  /**
   *
   * @type {Array<string>}
   * @memberof BikeBenefitOrderRead
   */
  revisedByBikeBenefitOrderIds: Array<string>;
  /**
   *
   * @type {Array<string>}
   * @memberof BikeBenefitOrderRead
   */
  correctedByBikeBenefitOrderIds: Array<string>;
  /**
   *
   * @type {number}
   * @memberof BikeBenefitOrderRead
   */
  taxablePackagePriceReduction: number;
}

/**
 * Check if a given object implements the BikeBenefitOrderRead interface.
 */
export function instanceOfBikeBenefitOrderRead(
  value: object,
): value is BikeBenefitOrderRead {
  if (!("vapausCode" in value) || value["vapausCode"] === undefined)
    return false;
  if (
    !("benefitDefinitionId" in value) ||
    value["benefitDefinitionId"] === undefined
  )
    return false;
  if (
    !("benefitDefinitionTermsId" in value) ||
    value["benefitDefinitionTermsId"] === undefined
  )
    return false;
  if (
    !("leasingPeriodMonths" in value) ||
    value["leasingPeriodMonths"] === undefined
  )
    return false;
  if (!("shopId" in value) || value["shopId"] === undefined) return false;
  if (!("bikeId" in value) || value["bikeId"] === undefined) return false;
  if (!("netBikePrice" in value) || value["netBikePrice"] === undefined)
    return false;
  if (!("currency" in value) || value["currency"] === undefined) return false;
  if (
    !("monthlyMaintenanceBudget" in value) ||
    value["monthlyMaintenanceBudget"] === undefined
  )
    return false;
  if (!("flatVatRate" in value) || value["flatVatRate"] === undefined)
    return false;
  if (
    !("hasExtendedWarranty" in value) ||
    value["hasExtendedWarranty"] === undefined
  )
    return false;
  if (
    !("isDownPaymentInsured" in value) ||
    value["isDownPaymentInsured"] === undefined
  )
    return false;
  if (!("id" in value) || value["id"] === undefined) return false;
  if (!("bike" in value) || value["bike"] === undefined) return false;
  if (!("bikePrice" in value) || value["bikePrice"] === undefined) return false;
  if (!("accessories" in value) || value["accessories"] === undefined)
    return false;
  if (
    !("additionalServices" in value) ||
    value["additionalServices"] === undefined
  )
    return false;
  if (!("state" in value) || value["state"] === undefined) return false;
  if (
    !("bikeBenefitContractIds" in value) ||
    value["bikeBenefitContractIds"] === undefined
  )
    return false;
  if (
    !("revisedByBikeBenefitOrderVapausCodes" in value) ||
    value["revisedByBikeBenefitOrderVapausCodes"] === undefined
  )
    return false;
  if (
    !("correctedByBikeBenefitOrderVapausCodes" in value) ||
    value["correctedByBikeBenefitOrderVapausCodes"] === undefined
  )
    return false;
  if (
    !("revisedByBikeBenefitOrderIds" in value) ||
    value["revisedByBikeBenefitOrderIds"] === undefined
  )
    return false;
  if (
    !("correctedByBikeBenefitOrderIds" in value) ||
    value["correctedByBikeBenefitOrderIds"] === undefined
  )
    return false;
  if (
    !("taxablePackagePriceReduction" in value) ||
    value["taxablePackagePriceReduction"] === undefined
  )
    return false;
  return true;
}

export function BikeBenefitOrderReadFromJSON(json: any): BikeBenefitOrderRead {
  return BikeBenefitOrderReadFromJSONTyped(json, false);
}

export function BikeBenefitOrderReadFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): BikeBenefitOrderRead {
  if (json == null) {
    return json;
  }
  return {
    vapausCode: json["vapaus_code"],
    orderNumber:
      json["order_number"] == null ? undefined : json["order_number"],
    correctsBikeBenefitContractId:
      json["corrects_bike_benefit_contract_id"] == null
        ? undefined
        : json["corrects_bike_benefit_contract_id"],
    revisesBikeBenefitContractId:
      json["revises_bike_benefit_contract_id"] == null
        ? undefined
        : json["revises_bike_benefit_contract_id"],
    revisionApplyDate:
      json["revision_apply_date"] == null
        ? undefined
        : new Date(json["revision_apply_date"]),
    legacyUserName:
      json["legacy_user_name"] == null ? undefined : json["legacy_user_name"],
    userId: json["user_id"] == null ? undefined : json["user_id"],
    isCancelled:
      json["is_cancelled"] == null ? undefined : json["is_cancelled"],
    cancellationReason:
      json["cancellation_reason"] == null
        ? undefined
        : BikeBenefitOrderCancellationReasonFromJSON(
            json["cancellation_reason"],
          ),
    currentVersion:
      json["current_version"] == null ? undefined : json["current_version"],
    acceptedVersion:
      json["accepted_version"] == null ? undefined : json["accepted_version"],
    benefitDefinitionId: json["benefit_definition_id"],
    benefitDefinitionTermsId: json["benefit_definition_terms_id"],
    leasingPeriodMonths: json["leasing_period_months"],
    shopId: json["shop_id"],
    bikeId: json["bike_id"],
    netBikePrice: json["net_bike_price"],
    currency: CurrencyFromJSON(json["currency"]),
    monthlyMaintenanceBudget: json["monthly_maintenance_budget"],
    flatVatRate: json["flat_vat_rate"],
    hasExtendedWarranty: json["has_extended_warranty"],
    fixedDownPaymentAmount:
      json["fixed_down_payment_amount"] == null
        ? undefined
        : json["fixed_down_payment_amount"],
    fixedMonthlyTaxableValue:
      json["fixed_monthly_taxable_value"] == null
        ? undefined
        : json["fixed_monthly_taxable_value"],
    netTaxablePackagePriceReduction:
      json["net_taxable_package_price_reduction"] == null
        ? undefined
        : json["net_taxable_package_price_reduction"],
    isDownPaymentInsured: json["is_down_payment_insured"],
    notes: json["notes"] == null ? undefined : json["notes"],
    isBillable: json["is_billable"] == null ? undefined : json["is_billable"],
    cancellationCoverage:
      json["cancellation_coverage"] == null
        ? undefined
        : BenefitCancellationCoverageFromJSON(json["cancellation_coverage"]),
    orderingMethod:
      json["ordering_method"] == null
        ? undefined
        : BikeBenefitOrderingMethodFromJSON(json["ordering_method"]),
    id: json["id"],
    createdAt:
      json["created_at"] == null ? undefined : new Date(json["created_at"]),
    updatedAt:
      json["updated_at"] == null ? undefined : new Date(json["updated_at"]),
    user: json["user"] == null ? undefined : UserReadFromJSON(json["user"]),
    bike: BikeAdminReadFromJSON(json["bike"]),
    bikePrice: json["bike_price"],
    accessories: (json["accessories"] as Array<any>).map(AccessoryFromJSON),
    additionalServices: (json["additional_services"] as Array<any>).map(
      AdditionalServiceFromJSON,
    ),
    state: BikeBenefitOrderStateFromJSON(json["state"]),
    deliveryTransport:
      json["delivery_transport"] == null
        ? undefined
        : BikeBenefitOrderDeliveryTransportFromJSON(json["delivery_transport"]),
    currentBikeBenefitContractId:
      json["current_bike_benefit_contract_id"] == null
        ? undefined
        : json["current_bike_benefit_contract_id"],
    bikeBenefitContractIds: json["bike_benefit_contract_ids"],
    deliveryDate:
      json["delivery_date"] == null
        ? undefined
        : new Date(json["delivery_date"]),
    revisesBikeBenefitContractVapausCode:
      json["revises_bike_benefit_contract_vapaus_code"] == null
        ? undefined
        : json["revises_bike_benefit_contract_vapaus_code"],
    correctsBikeBenefitContractVapausCode:
      json["corrects_bike_benefit_contract_vapaus_code"] == null
        ? undefined
        : json["corrects_bike_benefit_contract_vapaus_code"],
    revisedByBikeBenefitOrderVapausCodes:
      json["revised_by_bike_benefit_order_vapaus_codes"],
    correctedByBikeBenefitOrderVapausCodes:
      json["corrected_by_bike_benefit_order_vapaus_codes"],
    revisedByBikeBenefitOrderIds: json["revised_by_bike_benefit_order_ids"],
    correctedByBikeBenefitOrderIds: json["corrected_by_bike_benefit_order_ids"],
    taxablePackagePriceReduction: json["taxable_package_price_reduction"],
  };
}

export function BikeBenefitOrderReadToJSON(json: any): BikeBenefitOrderRead {
  return BikeBenefitOrderReadToJSONTyped(json, false);
}

export function BikeBenefitOrderReadToJSONTyped(
  value?: BikeBenefitOrderRead | null,
  ignoreDiscriminator: boolean = false,
): any {
  if (value == null) {
    return value;
  }

  return {
    vapaus_code: value["vapausCode"],
    order_number: value["orderNumber"],
    corrects_bike_benefit_contract_id: value["correctsBikeBenefitContractId"],
    revises_bike_benefit_contract_id: value["revisesBikeBenefitContractId"],
    revision_apply_date:
      value["revisionApplyDate"] == null
        ? undefined
        : value["revisionApplyDate"].toISOString().substring(0, 10),
    legacy_user_name: value["legacyUserName"],
    user_id: value["userId"],
    is_cancelled: value["isCancelled"],
    cancellation_reason: BikeBenefitOrderCancellationReasonToJSON(
      value["cancellationReason"],
    ),
    current_version: value["currentVersion"],
    accepted_version: value["acceptedVersion"],
    benefit_definition_id: value["benefitDefinitionId"],
    benefit_definition_terms_id: value["benefitDefinitionTermsId"],
    leasing_period_months: value["leasingPeriodMonths"],
    shop_id: value["shopId"],
    bike_id: value["bikeId"],
    net_bike_price: value["netBikePrice"],
    currency: CurrencyToJSON(value["currency"]),
    monthly_maintenance_budget: value["monthlyMaintenanceBudget"],
    flat_vat_rate: value["flatVatRate"],
    has_extended_warranty: value["hasExtendedWarranty"],
    fixed_down_payment_amount: value["fixedDownPaymentAmount"],
    fixed_monthly_taxable_value: value["fixedMonthlyTaxableValue"],
    net_taxable_package_price_reduction:
      value["netTaxablePackagePriceReduction"],
    is_down_payment_insured: value["isDownPaymentInsured"],
    notes: value["notes"],
    is_billable: value["isBillable"],
    cancellation_coverage: BenefitCancellationCoverageToJSON(
      value["cancellationCoverage"],
    ),
    ordering_method: BikeBenefitOrderingMethodToJSON(value["orderingMethod"]),
    id: value["id"],
    created_at:
      value["createdAt"] == null ? undefined : value["createdAt"].toISOString(),
    updated_at:
      value["updatedAt"] == null ? undefined : value["updatedAt"].toISOString(),
    user: UserReadToJSON(value["user"]),
    bike: BikeAdminReadToJSON(value["bike"]),
    bike_price: value["bikePrice"],
    accessories: (value["accessories"] as Array<any>).map(AccessoryToJSON),
    additional_services: (value["additionalServices"] as Array<any>).map(
      AdditionalServiceToJSON,
    ),
    state: BikeBenefitOrderStateToJSON(value["state"]),
    delivery_transport: BikeBenefitOrderDeliveryTransportToJSON(
      value["deliveryTransport"],
    ),
    current_bike_benefit_contract_id: value["currentBikeBenefitContractId"],
    bike_benefit_contract_ids: value["bikeBenefitContractIds"],
    delivery_date:
      value["deliveryDate"] == null
        ? undefined
        : value["deliveryDate"].toISOString().substring(0, 10),
    revises_bike_benefit_contract_vapaus_code:
      value["revisesBikeBenefitContractVapausCode"],
    corrects_bike_benefit_contract_vapaus_code:
      value["correctsBikeBenefitContractVapausCode"],
    revised_by_bike_benefit_order_vapaus_codes:
      value["revisedByBikeBenefitOrderVapausCodes"],
    corrected_by_bike_benefit_order_vapaus_codes:
      value["correctedByBikeBenefitOrderVapausCodes"],
    revised_by_bike_benefit_order_ids: value["revisedByBikeBenefitOrderIds"],
    corrected_by_bike_benefit_order_ids:
      value["correctedByBikeBenefitOrderIds"],
    taxable_package_price_reduction: value["taxablePackagePriceReduction"],
  };
}
