/* tslint:disable */

/* eslint-disable */

/**
 * Vapaus
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import type {
  BikeBenefitContractForMaintenanceRead,
  BikeBenefitContractRead,
  BikeBenefitOrderCreateUpdate,
  BikeBenefitOrderExtraRead,
  BikeBenefitOrderRead,
  BikeBenefitOrderState,
  BodyShopBenefitFinaliseBikeBenefitOrder,
  ErrorResponse,
  ErrorWithCodeResponse,
  HTTPValidationError,
  OrderDirection,
  PaginatedBikeBenefitContractOut,
  PaginatedBikeBenefitOrderSummaryOut,
  SupportedLanguagesEnum,
} from "../models/index";
import {
  BikeBenefitContractForMaintenanceReadFromJSON,
  BikeBenefitContractForMaintenanceReadToJSON,
  BikeBenefitContractReadFromJSON,
  BikeBenefitContractReadToJSON,
  BikeBenefitOrderCreateUpdateFromJSON,
  BikeBenefitOrderCreateUpdateToJSON,
  BikeBenefitOrderExtraReadFromJSON,
  BikeBenefitOrderExtraReadToJSON,
  BikeBenefitOrderReadFromJSON,
  BikeBenefitOrderReadToJSON,
  BikeBenefitOrderStateFromJSON,
  BikeBenefitOrderStateToJSON,
  BodyShopBenefitFinaliseBikeBenefitOrderFromJSON,
  BodyShopBenefitFinaliseBikeBenefitOrderToJSON,
  ErrorResponseFromJSON,
  ErrorResponseToJSON,
  ErrorWithCodeResponseFromJSON,
  ErrorWithCodeResponseToJSON,
  HTTPValidationErrorFromJSON,
  HTTPValidationErrorToJSON,
  OrderDirectionFromJSON,
  OrderDirectionToJSON,
  PaginatedBikeBenefitContractOutFromJSON,
  PaginatedBikeBenefitContractOutToJSON,
  PaginatedBikeBenefitOrderSummaryOutFromJSON,
  PaginatedBikeBenefitOrderSummaryOutToJSON,
  SupportedLanguagesEnumFromJSON,
  SupportedLanguagesEnumToJSON,
} from "../models/index";
import * as runtime from "../runtime";

export interface ShopBenefitCancelBikeBenefitOrderRequest {
  bikeBenefitOrderId: string;
}

export interface ShopBenefitCreateBikeBenefitOrderRequest {
  bikeBenefitOrderCreateUpdate: BikeBenefitOrderCreateUpdate;
}

export interface ShopBenefitFinaliseBikeBenefitOrderRequest {
  bikeBenefitOrderId: string;
  bodyShopBenefitFinaliseBikeBenefitOrder: BodyShopBenefitFinaliseBikeBenefitOrder;
}

export interface ShopBenefitGetBikeBenefitContractRequest {
  bikeBenefitContractId: string;
}

export interface ShopBenefitGetBikeBenefitOrderRequest {
  orderId: string;
}

export interface ShopBenefitSearchBikeBenefitContractsRequest {
  q?: string;
  ids?: Array<string>;
  skip?: number;
  limit?: number;
  sortField?: string;
  sortDirection?: OrderDirection;
  xFilterIds?: string;
}

export interface ShopBenefitSearchBikeBenefitOrdersRequest {
  shopId: string;
  states?: Set<BikeBenefitOrderState>;
  q?: string;
  ids?: Array<string>;
  skip?: number;
  limit?: number;
  sortField?: string;
  sortDirection?: OrderDirection;
  xFilterIds?: string;
}

export interface ShopBenefitSearchContractForMaintenanceRequest {
  q: string;
}

export interface ShopBenefitSendBikeBenefitOrderInstructionsEmailRequest {
  bikeBenefitOrderId: string;
  emailTo: string;
  language: SupportedLanguagesEnum;
}

export interface ShopBenefitUpdateBikeBenefitOrderRequest {
  bikeBenefitOrderId: string;
  bikeBenefitOrderCreateUpdate: BikeBenefitOrderCreateUpdate;
}

/**
 *
 */
export class ShopBenefitApi extends runtime.BaseAPI {
  /**
   * Cancel Bike Benefit Order
   */
  async shopBenefitCancelBikeBenefitOrderRaw(
    requestParameters: ShopBenefitCancelBikeBenefitOrderRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<BikeBenefitOrderRead>> {
    if (requestParameters["bikeBenefitOrderId"] == null) {
      throw new runtime.RequiredError(
        "bikeBenefitOrderId",
        'Required parameter "bikeBenefitOrderId" was null or undefined when calling shopBenefitCancelBikeBenefitOrder().',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      headerParameters["Authorization"] = await this.configuration.accessToken(
        "OAuth2PasswordBearer",
        [],
      );
    }

    const response = await this.request(
      {
        path: `/shop-api/bike-benefit-orders/{bike_benefit_order_id}/cancel`.replace(
          `{${"bike_benefit_order_id"}}`,
          encodeURIComponent(String(requestParameters["bikeBenefitOrderId"])),
        ),
        method: "POST",
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      BikeBenefitOrderReadFromJSON(jsonValue),
    );
  }

  /**
   * Cancel Bike Benefit Order
   */
  async shopBenefitCancelBikeBenefitOrder(
    requestParameters: ShopBenefitCancelBikeBenefitOrderRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<BikeBenefitOrderRead> {
    const response = await this.shopBenefitCancelBikeBenefitOrderRaw(
      requestParameters,
      initOverrides,
    );
    return await response.value();
  }

  /**
   * Create Bike Benefit Order
   */
  async shopBenefitCreateBikeBenefitOrderRaw(
    requestParameters: ShopBenefitCreateBikeBenefitOrderRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<BikeBenefitOrderRead>> {
    if (requestParameters["bikeBenefitOrderCreateUpdate"] == null) {
      throw new runtime.RequiredError(
        "bikeBenefitOrderCreateUpdate",
        'Required parameter "bikeBenefitOrderCreateUpdate" was null or undefined when calling shopBenefitCreateBikeBenefitOrder().',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      headerParameters["Authorization"] = await this.configuration.accessToken(
        "OAuth2PasswordBearer",
        [],
      );
    }

    const response = await this.request(
      {
        path: `/shop-api/bike-benefit-orders`,
        method: "POST",
        headers: headerParameters,
        query: queryParameters,
        body: BikeBenefitOrderCreateUpdateToJSON(
          requestParameters["bikeBenefitOrderCreateUpdate"],
        ),
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      BikeBenefitOrderReadFromJSON(jsonValue),
    );
  }

  /**
   * Create Bike Benefit Order
   */
  async shopBenefitCreateBikeBenefitOrder(
    requestParameters: ShopBenefitCreateBikeBenefitOrderRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<BikeBenefitOrderRead> {
    const response = await this.shopBenefitCreateBikeBenefitOrderRaw(
      requestParameters,
      initOverrides,
    );
    return await response.value();
  }

  /**
   * Finalise Bike Benefit Order
   */
  async shopBenefitFinaliseBikeBenefitOrderRaw(
    requestParameters: ShopBenefitFinaliseBikeBenefitOrderRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<BikeBenefitOrderRead>> {
    if (requestParameters["bikeBenefitOrderId"] == null) {
      throw new runtime.RequiredError(
        "bikeBenefitOrderId",
        'Required parameter "bikeBenefitOrderId" was null or undefined when calling shopBenefitFinaliseBikeBenefitOrder().',
      );
    }

    if (requestParameters["bodyShopBenefitFinaliseBikeBenefitOrder"] == null) {
      throw new runtime.RequiredError(
        "bodyShopBenefitFinaliseBikeBenefitOrder",
        'Required parameter "bodyShopBenefitFinaliseBikeBenefitOrder" was null or undefined when calling shopBenefitFinaliseBikeBenefitOrder().',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      headerParameters["Authorization"] = await this.configuration.accessToken(
        "OAuth2PasswordBearer",
        [],
      );
    }

    const response = await this.request(
      {
        path: `/shop-api/bike-benefit-orders/{bike_benefit_order_id}/finalise`.replace(
          `{${"bike_benefit_order_id"}}`,
          encodeURIComponent(String(requestParameters["bikeBenefitOrderId"])),
        ),
        method: "POST",
        headers: headerParameters,
        query: queryParameters,
        body: BodyShopBenefitFinaliseBikeBenefitOrderToJSON(
          requestParameters["bodyShopBenefitFinaliseBikeBenefitOrder"],
        ),
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      BikeBenefitOrderReadFromJSON(jsonValue),
    );
  }

  /**
   * Finalise Bike Benefit Order
   */
  async shopBenefitFinaliseBikeBenefitOrder(
    requestParameters: ShopBenefitFinaliseBikeBenefitOrderRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<BikeBenefitOrderRead> {
    const response = await this.shopBenefitFinaliseBikeBenefitOrderRaw(
      requestParameters,
      initOverrides,
    );
    return await response.value();
  }

  /**
   * Get Bike Benefit Contract
   */
  async shopBenefitGetBikeBenefitContractRaw(
    requestParameters: ShopBenefitGetBikeBenefitContractRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<BikeBenefitContractRead>> {
    if (requestParameters["bikeBenefitContractId"] == null) {
      throw new runtime.RequiredError(
        "bikeBenefitContractId",
        'Required parameter "bikeBenefitContractId" was null or undefined when calling shopBenefitGetBikeBenefitContract().',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      headerParameters["Authorization"] = await this.configuration.accessToken(
        "OAuth2PasswordBearer",
        [],
      );
    }

    const response = await this.request(
      {
        path: `/shop-api/bike-benefit-contracts/{bike_benefit_contract_id}`.replace(
          `{${"bike_benefit_contract_id"}}`,
          encodeURIComponent(
            String(requestParameters["bikeBenefitContractId"]),
          ),
        ),
        method: "GET",
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      BikeBenefitContractReadFromJSON(jsonValue),
    );
  }

  /**
   * Get Bike Benefit Contract
   */
  async shopBenefitGetBikeBenefitContract(
    requestParameters: ShopBenefitGetBikeBenefitContractRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<BikeBenefitContractRead> {
    const response = await this.shopBenefitGetBikeBenefitContractRaw(
      requestParameters,
      initOverrides,
    );
    return await response.value();
  }

  /**
   * Get Bike Benefit Order
   */
  async shopBenefitGetBikeBenefitOrderRaw(
    requestParameters: ShopBenefitGetBikeBenefitOrderRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<BikeBenefitOrderExtraRead>> {
    if (requestParameters["orderId"] == null) {
      throw new runtime.RequiredError(
        "orderId",
        'Required parameter "orderId" was null or undefined when calling shopBenefitGetBikeBenefitOrder().',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      headerParameters["Authorization"] = await this.configuration.accessToken(
        "OAuth2PasswordBearer",
        [],
      );
    }

    const response = await this.request(
      {
        path: `/shop-api/bike-benefit-orders/{order_id}`.replace(
          `{${"order_id"}}`,
          encodeURIComponent(String(requestParameters["orderId"])),
        ),
        method: "GET",
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      BikeBenefitOrderExtraReadFromJSON(jsonValue),
    );
  }

  /**
   * Get Bike Benefit Order
   */
  async shopBenefitGetBikeBenefitOrder(
    requestParameters: ShopBenefitGetBikeBenefitOrderRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<BikeBenefitOrderExtraRead> {
    const response = await this.shopBenefitGetBikeBenefitOrderRaw(
      requestParameters,
      initOverrides,
    );
    return await response.value();
  }

  /**
   * Search Bike Benefit Contracts
   */
  async shopBenefitSearchBikeBenefitContractsRaw(
    requestParameters: ShopBenefitSearchBikeBenefitContractsRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<PaginatedBikeBenefitContractOut>> {
    const queryParameters: any = {};

    if (requestParameters["q"] != null) {
      queryParameters["q"] = requestParameters["q"];
    }

    if (requestParameters["ids"] != null) {
      queryParameters["ids"] = requestParameters["ids"];
    }

    if (requestParameters["skip"] != null) {
      queryParameters["skip"] = requestParameters["skip"];
    }

    if (requestParameters["limit"] != null) {
      queryParameters["limit"] = requestParameters["limit"];
    }

    if (requestParameters["sortField"] != null) {
      queryParameters["sort_field"] = requestParameters["sortField"];
    }

    if (requestParameters["sortDirection"] != null) {
      queryParameters["sort_direction"] = requestParameters["sortDirection"];
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (requestParameters["xFilterIds"] != null) {
      headerParameters["X-Filter-Ids"] = String(
        requestParameters["xFilterIds"],
      );
    }

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      headerParameters["Authorization"] = await this.configuration.accessToken(
        "OAuth2PasswordBearer",
        [],
      );
    }

    const response = await this.request(
      {
        path: `/shop-api/bike-benefit-contracts`,
        method: "GET",
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      PaginatedBikeBenefitContractOutFromJSON(jsonValue),
    );
  }

  /**
   * Search Bike Benefit Contracts
   */
  async shopBenefitSearchBikeBenefitContracts(
    requestParameters: ShopBenefitSearchBikeBenefitContractsRequest = {},
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<PaginatedBikeBenefitContractOut> {
    const response = await this.shopBenefitSearchBikeBenefitContractsRaw(
      requestParameters,
      initOverrides,
    );
    return await response.value();
  }

  /**
   * Search Bike Benefit Orders
   */
  async shopBenefitSearchBikeBenefitOrdersRaw(
    requestParameters: ShopBenefitSearchBikeBenefitOrdersRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<PaginatedBikeBenefitOrderSummaryOut>> {
    if (requestParameters["shopId"] == null) {
      throw new runtime.RequiredError(
        "shopId",
        'Required parameter "shopId" was null or undefined when calling shopBenefitSearchBikeBenefitOrders().',
      );
    }

    const queryParameters: any = {};

    if (requestParameters["shopId"] != null) {
      queryParameters["shop_id"] = requestParameters["shopId"];
    }

    if (requestParameters["states"] != null) {
      queryParameters["states"] = requestParameters["states"];
    }

    if (requestParameters["q"] != null) {
      queryParameters["q"] = requestParameters["q"];
    }

    if (requestParameters["ids"] != null) {
      queryParameters["ids"] = requestParameters["ids"];
    }

    if (requestParameters["skip"] != null) {
      queryParameters["skip"] = requestParameters["skip"];
    }

    if (requestParameters["limit"] != null) {
      queryParameters["limit"] = requestParameters["limit"];
    }

    if (requestParameters["sortField"] != null) {
      queryParameters["sort_field"] = requestParameters["sortField"];
    }

    if (requestParameters["sortDirection"] != null) {
      queryParameters["sort_direction"] = requestParameters["sortDirection"];
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (requestParameters["xFilterIds"] != null) {
      headerParameters["X-Filter-Ids"] = String(
        requestParameters["xFilterIds"],
      );
    }

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      headerParameters["Authorization"] = await this.configuration.accessToken(
        "OAuth2PasswordBearer",
        [],
      );
    }

    const response = await this.request(
      {
        path: `/shop-api/bike-benefit-orders`,
        method: "GET",
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      PaginatedBikeBenefitOrderSummaryOutFromJSON(jsonValue),
    );
  }

  /**
   * Search Bike Benefit Orders
   */
  async shopBenefitSearchBikeBenefitOrders(
    requestParameters: ShopBenefitSearchBikeBenefitOrdersRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<PaginatedBikeBenefitOrderSummaryOut> {
    const response = await this.shopBenefitSearchBikeBenefitOrdersRaw(
      requestParameters,
      initOverrides,
    );
    return await response.value();
  }

  /**
   * Search Contract For Maintenance
   */
  async shopBenefitSearchContractForMaintenanceRaw(
    requestParameters: ShopBenefitSearchContractForMaintenanceRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<
    runtime.ApiResponse<Array<BikeBenefitContractForMaintenanceRead>>
  > {
    if (requestParameters["q"] == null) {
      throw new runtime.RequiredError(
        "q",
        'Required parameter "q" was null or undefined when calling shopBenefitSearchContractForMaintenance().',
      );
    }

    const queryParameters: any = {};

    if (requestParameters["q"] != null) {
      queryParameters["q"] = requestParameters["q"];
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      headerParameters["Authorization"] = await this.configuration.accessToken(
        "OAuth2PasswordBearer",
        [],
      );
    }

    const response = await this.request(
      {
        path: `/shop-api/bike-benefit-contracts/search-for-maintenance`,
        method: "GET",
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      jsonValue.map(BikeBenefitContractForMaintenanceReadFromJSON),
    );
  }

  /**
   * Search Contract For Maintenance
   */
  async shopBenefitSearchContractForMaintenance(
    requestParameters: ShopBenefitSearchContractForMaintenanceRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<Array<BikeBenefitContractForMaintenanceRead>> {
    const response = await this.shopBenefitSearchContractForMaintenanceRaw(
      requestParameters,
      initOverrides,
    );
    return await response.value();
  }

  /**
   * Send Bike Benefit Order Instructions Email
   */
  async shopBenefitSendBikeBenefitOrderInstructionsEmailRaw(
    requestParameters: ShopBenefitSendBikeBenefitOrderInstructionsEmailRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<any>> {
    if (requestParameters["bikeBenefitOrderId"] == null) {
      throw new runtime.RequiredError(
        "bikeBenefitOrderId",
        'Required parameter "bikeBenefitOrderId" was null or undefined when calling shopBenefitSendBikeBenefitOrderInstructionsEmail().',
      );
    }

    if (requestParameters["emailTo"] == null) {
      throw new runtime.RequiredError(
        "emailTo",
        'Required parameter "emailTo" was null or undefined when calling shopBenefitSendBikeBenefitOrderInstructionsEmail().',
      );
    }

    if (requestParameters["language"] == null) {
      throw new runtime.RequiredError(
        "language",
        'Required parameter "language" was null or undefined when calling shopBenefitSendBikeBenefitOrderInstructionsEmail().',
      );
    }

    const queryParameters: any = {};

    if (requestParameters["emailTo"] != null) {
      queryParameters["email_to"] = requestParameters["emailTo"];
    }

    if (requestParameters["language"] != null) {
      queryParameters["language"] = requestParameters["language"];
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      headerParameters["Authorization"] = await this.configuration.accessToken(
        "OAuth2PasswordBearer",
        [],
      );
    }

    const response = await this.request(
      {
        path: `/shop-api/bike-benefit-orders/{bike_benefit_order_id}/send-instructions`.replace(
          `{${"bike_benefit_order_id"}}`,
          encodeURIComponent(String(requestParameters["bikeBenefitOrderId"])),
        ),
        method: "POST",
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    if (this.isJsonMime(response.headers.get("content-type"))) {
      return new runtime.JSONApiResponse<any>(response);
    } else {
      return new runtime.TextApiResponse(response) as any;
    }
  }

  /**
   * Send Bike Benefit Order Instructions Email
   */
  async shopBenefitSendBikeBenefitOrderInstructionsEmail(
    requestParameters: ShopBenefitSendBikeBenefitOrderInstructionsEmailRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<any> {
    const response =
      await this.shopBenefitSendBikeBenefitOrderInstructionsEmailRaw(
        requestParameters,
        initOverrides,
      );
    return await response.value();
  }

  /**
   * Update Bike Benefit Order
   */
  async shopBenefitUpdateBikeBenefitOrderRaw(
    requestParameters: ShopBenefitUpdateBikeBenefitOrderRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<BikeBenefitOrderRead>> {
    if (requestParameters["bikeBenefitOrderId"] == null) {
      throw new runtime.RequiredError(
        "bikeBenefitOrderId",
        'Required parameter "bikeBenefitOrderId" was null or undefined when calling shopBenefitUpdateBikeBenefitOrder().',
      );
    }

    if (requestParameters["bikeBenefitOrderCreateUpdate"] == null) {
      throw new runtime.RequiredError(
        "bikeBenefitOrderCreateUpdate",
        'Required parameter "bikeBenefitOrderCreateUpdate" was null or undefined when calling shopBenefitUpdateBikeBenefitOrder().',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      headerParameters["Authorization"] = await this.configuration.accessToken(
        "OAuth2PasswordBearer",
        [],
      );
    }

    const response = await this.request(
      {
        path: `/shop-api/bike-benefit-orders/{bike_benefit_order_id}`.replace(
          `{${"bike_benefit_order_id"}}`,
          encodeURIComponent(String(requestParameters["bikeBenefitOrderId"])),
        ),
        method: "PUT",
        headers: headerParameters,
        query: queryParameters,
        body: BikeBenefitOrderCreateUpdateToJSON(
          requestParameters["bikeBenefitOrderCreateUpdate"],
        ),
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      BikeBenefitOrderReadFromJSON(jsonValue),
    );
  }

  /**
   * Update Bike Benefit Order
   */
  async shopBenefitUpdateBikeBenefitOrder(
    requestParameters: ShopBenefitUpdateBikeBenefitOrderRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<BikeBenefitOrderRead> {
    const response = await this.shopBenefitUpdateBikeBenefitOrderRaw(
      requestParameters,
      initOverrides,
    );
    return await response.value();
  }
}
