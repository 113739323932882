/* tslint:disable */

/* eslint-disable */

/**
 * Vapaus
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { mapValues } from "../runtime";
import type { Currency } from "./Currency";
import {
  CurrencyFromJSON,
  CurrencyFromJSONTyped,
  CurrencyToJSON,
  CurrencyToJSONTyped,
} from "./Currency";
import type { WorkTypes } from "./WorkTypes";
import {
  WorkTypesFromJSON,
  WorkTypesFromJSONTyped,
  WorkTypesToJSON,
  WorkTypesToJSONTyped,
} from "./WorkTypes";

/**
 *
 * @export
 * @interface AdminMaintenanceEventCreate
 */
export interface AdminMaintenanceEventCreate {
  /**
   *
   * @type {number}
   * @memberof AdminMaintenanceEventCreate
   */
  partsPrice: number;
  /**
   *
   * @type {number}
   * @memberof AdminMaintenanceEventCreate
   */
  workPrice: number;
  /**
   *
   * @type {WorkTypes}
   * @memberof AdminMaintenanceEventCreate
   */
  workType: WorkTypes;
  /**
   *
   * @type {string}
   * @memberof AdminMaintenanceEventCreate
   */
  workDescription: string;
  /**
   *
   * @type {Date}
   * @memberof AdminMaintenanceEventCreate
   */
  workDate: Date;
  /**
   *
   * @type {string}
   * @memberof AdminMaintenanceEventCreate
   */
  shopId: string;
  /**
   *
   * @type {string}
   * @memberof AdminMaintenanceEventCreate
   */
  bikeId: string;
  /**
   *
   * @type {string}
   * @memberof AdminMaintenanceEventCreate
   */
  bikeBenefitContractId?: string;
  /**
   *
   * @type {Currency}
   * @memberof AdminMaintenanceEventCreate
   */
  currency: Currency;
}

/**
 * Check if a given object implements the AdminMaintenanceEventCreate interface.
 */
export function instanceOfAdminMaintenanceEventCreate(
  value: object,
): value is AdminMaintenanceEventCreate {
  if (!("partsPrice" in value) || value["partsPrice"] === undefined)
    return false;
  if (!("workPrice" in value) || value["workPrice"] === undefined) return false;
  if (!("workType" in value) || value["workType"] === undefined) return false;
  if (!("workDescription" in value) || value["workDescription"] === undefined)
    return false;
  if (!("workDate" in value) || value["workDate"] === undefined) return false;
  if (!("shopId" in value) || value["shopId"] === undefined) return false;
  if (!("bikeId" in value) || value["bikeId"] === undefined) return false;
  if (!("currency" in value) || value["currency"] === undefined) return false;
  return true;
}

export function AdminMaintenanceEventCreateFromJSON(
  json: any,
): AdminMaintenanceEventCreate {
  return AdminMaintenanceEventCreateFromJSONTyped(json, false);
}

export function AdminMaintenanceEventCreateFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): AdminMaintenanceEventCreate {
  if (json == null) {
    return json;
  }
  return {
    partsPrice: json["parts_price"],
    workPrice: json["work_price"],
    workType: WorkTypesFromJSON(json["work_type"]),
    workDescription: json["work_description"],
    workDate: new Date(json["work_date"]),
    shopId: json["shop_id"],
    bikeId: json["bike_id"],
    bikeBenefitContractId:
      json["bike_benefit_contract_id"] == null
        ? undefined
        : json["bike_benefit_contract_id"],
    currency: CurrencyFromJSON(json["currency"]),
  };
}

export function AdminMaintenanceEventCreateToJSON(
  json: any,
): AdminMaintenanceEventCreate {
  return AdminMaintenanceEventCreateToJSONTyped(json, false);
}

export function AdminMaintenanceEventCreateToJSONTyped(
  value?: AdminMaintenanceEventCreate | null,
  ignoreDiscriminator: boolean = false,
): any {
  if (value == null) {
    return value;
  }

  return {
    parts_price: value["partsPrice"],
    work_price: value["workPrice"],
    work_type: WorkTypesToJSON(value["workType"]),
    work_description: value["workDescription"],
    work_date: value["workDate"].toISOString().substring(0, 10),
    shop_id: value["shopId"],
    bike_id: value["bikeId"],
    bike_benefit_contract_id: value["bikeBenefitContractId"],
    currency: CurrencyToJSON(value["currency"]),
  };
}
