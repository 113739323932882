import { useEffect } from "react";

import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { BikeBenefitOrderExtraRead, Currency } from "@vapaus/api-codegen";
import { DatePicker, Input, NumberInput, TextArea } from "@vapaus/form";
import { useCurrencyFormat, usePercentFormat } from "@vapaus/i18n";
import {
  Card,
  Checkbox,
  Flex,
  HorizontalLine,
  Radio,
  Typography,
} from "@vapaus/ui-v2";

import { BenefitOrderFormType, DeliveryMethod } from "../../utils";
import { BaseOrderFormSectionProps } from "../types";

type DeliveryFormProps = {
  editingOrder?: BikeBenefitOrderExtraRead;
} & BaseOrderFormSectionProps;

export function DeliveryForm({
  currency = Currency.Eur,
  vatRate,
  editingOrder,
}: DeliveryFormProps) {
  const { t } = useTranslation();
  const formatCurrency = useCurrencyFormat(currency);
  const formatPercent = usePercentFormat();

  const { register, watch, setValue, resetField } =
    useFormContext<BenefitOrderFormType>();
  const [deliveryMethod, addOrderNote, deliveryTransport] = watch([
    "deliveryMethod",
    "addOrderNote",
    "deliveryTransport",
  ]);

  useEffect(() => {
    if (deliveryMethod === DeliveryMethod.pickUp) {
      resetField("deliveryTransport");
    } else if (editingOrder && !deliveryTransport) {
      setValue("deliveryTransport", editingOrder.deliveryTransport);
    }
    if (deliveryTransport && !deliveryTransport.vatRate && vatRate) {
      setValue("deliveryTransport.vatRate", vatRate);
    }
  }, [
    deliveryMethod,
    deliveryTransport,
    editingOrder,
    vatRate,
    setValue,
    resetField,
  ]);

  useEffect(() => {
    if (!addOrderNote) {
      resetField("addOrderNote");
      resetField("orderNumber");
      resetField("notes");
    } else if (editingOrder) {
      setValue("orderNumber", editingOrder.orderNumber);
      setValue("notes", editingOrder.notes);
    }
  }, [addOrderNote, editingOrder, setValue, resetField]);

  return (
    <Card
      title={t("shopV2:orderForm.delivery.title")}
      subtitle={t("shopV2:orderForm.delivery.subtitle")}
    >
      <Flex direction="column" gap="md">
        {/* TODO: Let's add a radio group component to the design system and use it here */}
        <Radio
          label={t("shopV2:orderForm.delivery.pickup")}
          value={DeliveryMethod.pickUp}
          {...register("deliveryMethod")}
        />
        <Radio
          label={t("shopV2:orderForm.delivery.delivery")}
          value={DeliveryMethod.delivery}
          {...register("deliveryMethod")}
        />
        {deliveryMethod === DeliveryMethod.delivery && (
          <Flex direction="column">
            <Flex xsDirection="column" align="flex-start">
              <NumberInput
                name="deliveryTransport.priceGross"
                label={t("shopV2:orderForm.delivery.priceIncludeVAT", {
                  vat: formatPercent(deliveryTransport?.vatRate ?? vatRate),
                })}
                placeholder={formatCurrency(0)}
                fullWidth
              />
              <DatePicker
                name="deliveryTransport.expectedDeliveryDate"
                label={t("shopV2:orderForm.delivery.estimatedDeliveryDate")}
                fullWidth
              />
            </Flex>
            <Input
              name="deliveryTransport.trackingUrl"
              placeholder={t("shopV2:orderForm.delivery.enterUrl")}
              label={t("shopV2:orderForm.delivery.trackingUrl")}
            />
          </Flex>
        )}
        <HorizontalLine marginBottom="none" marginTop="none" />
        <Checkbox
          checked={Boolean(addOrderNote)}
          label={
            <Typography weight="bold">
              {t("shopV2:orderForm.delivery.addOrderNumberOrNotes")}
            </Typography>
          }
          {...register("addOrderNote")}
        />
        {addOrderNote && (
          <>
            <Input
              name="orderNumber"
              placeholder={t("shopV2:orderForm.delivery.enterOrderNumber")}
            />
            <TextArea
              placeholder={t("shopV2:orderForm.delivery.writeNotes")}
              name="notes"
            />
          </>
        )}
      </Flex>
    </Card>
  );
}
