/* tslint:disable */

/* eslint-disable */

/**
 * Vapaus
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { mapValues } from "../runtime";
import type { WorkTypes } from "./WorkTypes";
import {
  WorkTypesFromJSON,
  WorkTypesFromJSONTyped,
  WorkTypesToJSON,
  WorkTypesToJSONTyped,
} from "./WorkTypes";

/**
 *
 * @export
 * @interface ShopMaintenanceEventCreate
 */
export interface ShopMaintenanceEventCreate {
  /**
   *
   * @type {number}
   * @memberof ShopMaintenanceEventCreate
   */
  partsPrice: number;
  /**
   *
   * @type {number}
   * @memberof ShopMaintenanceEventCreate
   */
  workPrice: number;
  /**
   *
   * @type {WorkTypes}
   * @memberof ShopMaintenanceEventCreate
   */
  workType: WorkTypes;
  /**
   *
   * @type {string}
   * @memberof ShopMaintenanceEventCreate
   */
  workDescription: string;
  /**
   *
   * @type {Date}
   * @memberof ShopMaintenanceEventCreate
   */
  workDate: Date;
  /**
   *
   * @type {string}
   * @memberof ShopMaintenanceEventCreate
   */
  shopId: string;
  /**
   *
   * @type {string}
   * @memberof ShopMaintenanceEventCreate
   */
  bikeBenefitContractId: string;
}

/**
 * Check if a given object implements the ShopMaintenanceEventCreate interface.
 */
export function instanceOfShopMaintenanceEventCreate(
  value: object,
): value is ShopMaintenanceEventCreate {
  if (!("partsPrice" in value) || value["partsPrice"] === undefined)
    return false;
  if (!("workPrice" in value) || value["workPrice"] === undefined) return false;
  if (!("workType" in value) || value["workType"] === undefined) return false;
  if (!("workDescription" in value) || value["workDescription"] === undefined)
    return false;
  if (!("workDate" in value) || value["workDate"] === undefined) return false;
  if (!("shopId" in value) || value["shopId"] === undefined) return false;
  if (
    !("bikeBenefitContractId" in value) ||
    value["bikeBenefitContractId"] === undefined
  )
    return false;
  return true;
}

export function ShopMaintenanceEventCreateFromJSON(
  json: any,
): ShopMaintenanceEventCreate {
  return ShopMaintenanceEventCreateFromJSONTyped(json, false);
}

export function ShopMaintenanceEventCreateFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): ShopMaintenanceEventCreate {
  if (json == null) {
    return json;
  }
  return {
    partsPrice: json["parts_price"],
    workPrice: json["work_price"],
    workType: WorkTypesFromJSON(json["work_type"]),
    workDescription: json["work_description"],
    workDate: new Date(json["work_date"]),
    shopId: json["shop_id"],
    bikeBenefitContractId: json["bike_benefit_contract_id"],
  };
}

export function ShopMaintenanceEventCreateToJSON(
  json: any,
): ShopMaintenanceEventCreate {
  return ShopMaintenanceEventCreateToJSONTyped(json, false);
}

export function ShopMaintenanceEventCreateToJSONTyped(
  value?: ShopMaintenanceEventCreate | null,
  ignoreDiscriminator: boolean = false,
): any {
  if (value == null) {
    return value;
  }

  return {
    parts_price: value["partsPrice"],
    work_price: value["workPrice"],
    work_type: WorkTypesToJSON(value["workType"]),
    work_description: value["workDescription"],
    work_date: value["workDate"].toISOString().substring(0, 10),
    shop_id: value["shopId"],
    bike_benefit_contract_id: value["bikeBenefitContractId"],
  };
}
