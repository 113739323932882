import { ReactElement } from "react";

import styled, { css } from "styled-components";

import { BikeBenefitContractForMaintenanceRead } from "@vapaus/api-codegen";
import { useCurrencyFormat } from "@vapaus/i18n";
import { ActionCard, PedalBikeIcon, Typography } from "@vapaus/ui-v2";

type SearchResultProps = {
  contract: BikeBenefitContractForMaintenanceRead;
  onClick: () => void;
};

export function SearchResult({
  contract,
  onClick,
}: SearchResultProps): ReactElement {
  const formatCurrency = useCurrencyFormat(contract.currency);

  return (
    <ActionCard
      icon={PedalBikeIcon}
      firstLine={`${contract.bike.bikeBrand.name} ${contract.bike.model}`}
      secondLine={contract.user.email}
      thirdLine={`${contract.bike.serialNumber} · ${contract.vapausCode}`}
      afterContent={
        <AfterContentWrapper>
          <Typography
            variant="h4"
            color={
              contract.remainingMaintenanceBudget <= 0 ? "error" : undefined
            }
          >
            {formatCurrency(contract.remainingMaintenanceBudget)}
          </Typography>
        </AfterContentWrapper>
      }
      onClick={onClick}
    />
  );
}

const AfterContentWrapper = styled.div`
  ${({ theme }) =>
    theme.breakpoints.xs.down(css`
      display: none;
    `)}
`;
