import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { BenefitDefinitionReadWithOrganisationSummary } from "@vapaus/api-codegen";
import { useCurrencyFormat } from "@vapaus/i18n";
import { PaletteVariants } from "@vapaus/ui-v2";

import { BenefitOrderFormType } from "../../utils";

export function useCalculationBar(
  benefitDefinition: BenefitDefinitionReadWithOrganisationSummary,
  hasFixedTaxableValue: boolean,
) {
  const formatCurrency = useCurrencyFormat(benefitDefinition.currency);
  const { t } = useTranslation();
  const {
    watch,
    formState: { errors },
  } = useFormContext<BenefitOrderFormType>();

  const [
    leasingPeriodMonths,
    fixedMonthlyTaxableValue,
    fixedDownPaymentAmount,
  ] = watch([
    "leasingPeriodMonths",
    "fixedMonthlyTaxableValue",
    "fixedDownPaymentAmount",
  ]);

  const packageMaximum =
    benefitDefinition.maximumTaxablePackagePrice ??
    benefitDefinition.maximumTotalPackagePrice ??
    0;
  const packageTotalMaximum = benefitDefinition.maximumTotalPackagePrice ?? 0;

  const packageMaximumText =
    t("shopV2:orderForm.calculationBar.maxPayment", {
      max: formatCurrency(packageMaximum),
    }) +
    (benefitDefinition.isDownpaymentAllowed
      ? ` (${formatCurrency(packageTotalMaximum)})`
      : "");

  const getBarThemeAndMessage = () => {
    let bgColor: PaletteVariants = "secondary1.light";
    let messageBgColor: PaletteVariants = "primary1.main";
    let barMessage: string | null = null;

    if (errors.root?.message) {
      bgColor = "secondary2.lighter";
      messageBgColor = "error";
      barMessage = errors.root?.message;
    } else if (fixedDownPaymentAmount > 0) {
      if (benefitDefinition.isDownpaymentAllowed) {
        bgColor = "secondary1.light";
        messageBgColor = "primary1.main";
        barMessage = t(
          "shopV2:orderForm.calculationBar.customMessage.downPaymentRequired",
        );
      } else {
        bgColor = "secondary2.lighter";
        messageBgColor = "error";
        barMessage = t(
          "shopV2:orderForm.calculationBar.customMessage.downPaymentNotAllowed",
        );
      }
    }
    return { bgColor, messageBgColor, barMessage };
  };

  const barThemeAndMessage = getBarThemeAndMessage();

  const leasingPeriodText =
    leasingPeriodMonths && !errors.root?.message
      ? t("shopV2:orderForm.calculationBar.leasingPeriodValue", {
          value: leasingPeriodMonths,
          min: benefitDefinition.minimumLeasingPeriod,
          max: benefitDefinition.maximumLeasingPeriod,
        })
      : "-";

  const monthlyPaymentText = fixedMonthlyTaxableValue
    ? `${formatCurrency(fixedMonthlyTaxableValue)} (${
        hasFixedTaxableValue
          ? t("shopV2:orderForm.calculationBar.fixed")
          : `${formatCurrency(
              benefitDefinition.minimumMonthlyTaxableValue,
            )}-${formatCurrency(benefitDefinition.maximumMonthlyTaxableValue)}`
      })`
    : "-";

  const downPaymentText = benefitDefinition.isDownpaymentAllowed
    ? formatCurrency(fixedDownPaymentAmount ?? 0)
    : t("shopV2:orderForm.calculationBar.notAllowed");

  return {
    barThemeAndMessage,
    leasingPeriodText,
    monthlyPaymentText,
    downPaymentText,
    packageMaximumText,
  };
}
