import { isArray, isObject, some, values } from "lodash-es";
import { FieldNamesMarkedBoolean } from "react-hook-form";

import { BenefitOrderFormType } from "../orderFormSchema";

export type ValueType = Partial<
  Readonly<FieldNamesMarkedBoolean<BenefitOrderFormType>>
>;

export function getIsFormDirty(originalValues: ValueType): boolean {
  return some(values(originalValues), (value) => {
    if (isObject(value) && !isArray(value)) {
      return getIsFormDirty(values(value) as ValueType);
    } else if (isArray(value)) {
      return getIsFormDirty(value as ValueType);
    }
    return value === true;
  });
}
