/* tslint:disable */

/* eslint-disable */

/**
 * Vapaus
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { mapValues } from "../runtime";
import type { Currency } from "./Currency";
import {
  CurrencyFromJSON,
  CurrencyFromJSONTyped,
  CurrencyToJSON,
  CurrencyToJSONTyped,
} from "./Currency";
import type { SaleInvoiceLine } from "./SaleInvoiceLine";
import {
  SaleInvoiceLineFromJSON,
  SaleInvoiceLineFromJSONTyped,
  SaleInvoiceLineToJSON,
  SaleInvoiceLineToJSONTyped,
} from "./SaleInvoiceLine";
import type { SaleInvoiceStatus } from "./SaleInvoiceStatus";
import {
  SaleInvoiceStatusFromJSON,
  SaleInvoiceStatusFromJSONTyped,
  SaleInvoiceStatusToJSON,
  SaleInvoiceStatusToJSONTyped,
} from "./SaleInvoiceStatus";
import type { SaleInvoiceTransaction } from "./SaleInvoiceTransaction";
import {
  SaleInvoiceTransactionFromJSON,
  SaleInvoiceTransactionFromJSONTyped,
  SaleInvoiceTransactionToJSON,
  SaleInvoiceTransactionToJSONTyped,
} from "./SaleInvoiceTransaction";
import type { StorageObjectRead } from "./StorageObjectRead";
import {
  StorageObjectReadFromJSON,
  StorageObjectReadFromJSONTyped,
  StorageObjectReadToJSON,
  StorageObjectReadToJSONTyped,
} from "./StorageObjectRead";
import type { SupportedCountriesEnum } from "./SupportedCountriesEnum";
import {
  SupportedCountriesEnumFromJSON,
  SupportedCountriesEnumFromJSONTyped,
  SupportedCountriesEnumToJSON,
  SupportedCountriesEnumToJSONTyped,
} from "./SupportedCountriesEnum";

/**
 *
 * @export
 * @interface SaleInvoiceRead
 */
export interface SaleInvoiceRead {
  /**
   *
   * @type {Date}
   * @memberof SaleInvoiceRead
   */
  date: Date;
  /**
   *
   * @type {Date}
   * @memberof SaleInvoiceRead
   */
  dueDate: Date;
  /**
   *
   * @type {Currency}
   * @memberof SaleInvoiceRead
   */
  currency: Currency;
  /**
   *
   * @type {string}
   * @memberof SaleInvoiceRead
   */
  pdfObjectId?: string;
  /**
   *
   * @type {string}
   * @memberof SaleInvoiceRead
   */
  customerUserId: string;
  /**
   *
   * @type {string}
   * @memberof SaleInvoiceRead
   */
  bikeBenefitOrderId?: string;
  /**
   *
   * @type {string}
   * @memberof SaleInvoiceRead
   */
  bikeBenefitContractId?: string;
  /**
   *
   * @type {string}
   * @memberof SaleInvoiceRead
   */
  invoiceNumberPrefix: string;
  /**
   *
   * @type {number}
   * @memberof SaleInvoiceRead
   */
  invoiceNumberSerial: number;
  /**
   *
   * @type {number}
   * @memberof SaleInvoiceRead
   */
  vat: number;
  /**
   *
   * @type {number}
   * @memberof SaleInvoiceRead
   */
  total: number;
  /**
   *
   * @type {string}
   * @memberof SaleInvoiceRead
   */
  customerFullName: string;
  /**
   *
   * @type {string}
   * @memberof SaleInvoiceRead
   */
  customerAddress: string;
  /**
   *
   * @type {string}
   * @memberof SaleInvoiceRead
   */
  customerPostCode: string;
  /**
   *
   * @type {string}
   * @memberof SaleInvoiceRead
   */
  customerCity: string;
  /**
   *
   * @type {SupportedCountriesEnum}
   * @memberof SaleInvoiceRead
   */
  customerCountry: SupportedCountriesEnum;
  /**
   *
   * @type {string}
   * @memberof SaleInvoiceRead
   */
  revertsInvoiceId?: string;
  /**
   *
   * @type {string}
   * @memberof SaleInvoiceRead
   */
  id: string;
  /**
   *
   * @type {Date}
   * @memberof SaleInvoiceRead
   */
  createdAt?: Date;
  /**
   *
   * @type {Date}
   * @memberof SaleInvoiceRead
   */
  updatedAt?: Date;
  /**
   *
   * @type {string}
   * @memberof SaleInvoiceRead
   */
  invoiceNumber: string;
  /**
   *
   * @type {number}
   * @memberof SaleInvoiceRead
   */
  balance: number;
  /**
   *
   * @type {SaleInvoiceStatus}
   * @memberof SaleInvoiceRead
   */
  status: SaleInvoiceStatus;
  /**
   *
   * @type {Array<SaleInvoiceLine>}
   * @memberof SaleInvoiceRead
   */
  saleInvoiceLines: Array<SaleInvoiceLine>;
  /**
   *
   * @type {StorageObjectRead}
   * @memberof SaleInvoiceRead
   */
  pdfObject?: StorageObjectRead;
  /**
   *
   * @type {Array<SaleInvoiceTransaction>}
   * @memberof SaleInvoiceRead
   */
  saleInvoiceTransactions: Array<SaleInvoiceTransaction>;
  /**
   *
   * @type {string}
   * @memberof SaleInvoiceRead
   */
  vapausCode?: string;
}

/**
 * Check if a given object implements the SaleInvoiceRead interface.
 */
export function instanceOfSaleInvoiceRead(
  value: object,
): value is SaleInvoiceRead {
  if (!("date" in value) || value["date"] === undefined) return false;
  if (!("dueDate" in value) || value["dueDate"] === undefined) return false;
  if (!("currency" in value) || value["currency"] === undefined) return false;
  if (!("customerUserId" in value) || value["customerUserId"] === undefined)
    return false;
  if (
    !("invoiceNumberPrefix" in value) ||
    value["invoiceNumberPrefix"] === undefined
  )
    return false;
  if (
    !("invoiceNumberSerial" in value) ||
    value["invoiceNumberSerial"] === undefined
  )
    return false;
  if (!("vat" in value) || value["vat"] === undefined) return false;
  if (!("total" in value) || value["total"] === undefined) return false;
  if (!("customerFullName" in value) || value["customerFullName"] === undefined)
    return false;
  if (!("customerAddress" in value) || value["customerAddress"] === undefined)
    return false;
  if (!("customerPostCode" in value) || value["customerPostCode"] === undefined)
    return false;
  if (!("customerCity" in value) || value["customerCity"] === undefined)
    return false;
  if (!("customerCountry" in value) || value["customerCountry"] === undefined)
    return false;
  if (!("id" in value) || value["id"] === undefined) return false;
  if (!("invoiceNumber" in value) || value["invoiceNumber"] === undefined)
    return false;
  if (!("balance" in value) || value["balance"] === undefined) return false;
  if (!("status" in value) || value["status"] === undefined) return false;
  if (!("saleInvoiceLines" in value) || value["saleInvoiceLines"] === undefined)
    return false;
  if (
    !("saleInvoiceTransactions" in value) ||
    value["saleInvoiceTransactions"] === undefined
  )
    return false;
  return true;
}

export function SaleInvoiceReadFromJSON(json: any): SaleInvoiceRead {
  return SaleInvoiceReadFromJSONTyped(json, false);
}

export function SaleInvoiceReadFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): SaleInvoiceRead {
  if (json == null) {
    return json;
  }
  return {
    date: new Date(json["date"]),
    dueDate: new Date(json["due_date"]),
    currency: CurrencyFromJSON(json["currency"]),
    pdfObjectId:
      json["pdf_object_id"] == null ? undefined : json["pdf_object_id"],
    customerUserId: json["customer_user_id"],
    bikeBenefitOrderId:
      json["bike_benefit_order_id"] == null
        ? undefined
        : json["bike_benefit_order_id"],
    bikeBenefitContractId:
      json["bike_benefit_contract_id"] == null
        ? undefined
        : json["bike_benefit_contract_id"],
    invoiceNumberPrefix: json["invoice_number_prefix"],
    invoiceNumberSerial: json["invoice_number_serial"],
    vat: json["vat"],
    total: json["total"],
    customerFullName: json["customer_full_name"],
    customerAddress: json["customer_address"],
    customerPostCode: json["customer_post_code"],
    customerCity: json["customer_city"],
    customerCountry: SupportedCountriesEnumFromJSON(json["customer_country"]),
    revertsInvoiceId:
      json["reverts_invoice_id"] == null
        ? undefined
        : json["reverts_invoice_id"],
    id: json["id"],
    createdAt:
      json["created_at"] == null ? undefined : new Date(json["created_at"]),
    updatedAt:
      json["updated_at"] == null ? undefined : new Date(json["updated_at"]),
    invoiceNumber: json["invoice_number"],
    balance: json["balance"],
    status: SaleInvoiceStatusFromJSON(json["status"]),
    saleInvoiceLines: (json["sale_invoice_lines"] as Array<any>).map(
      SaleInvoiceLineFromJSON,
    ),
    pdfObject:
      json["pdf_object"] == null
        ? undefined
        : StorageObjectReadFromJSON(json["pdf_object"]),
    saleInvoiceTransactions: (
      json["sale_invoice_transactions"] as Array<any>
    ).map(SaleInvoiceTransactionFromJSON),
    vapausCode: json["vapaus_code"] == null ? undefined : json["vapaus_code"],
  };
}

export function SaleInvoiceReadToJSON(json: any): SaleInvoiceRead {
  return SaleInvoiceReadToJSONTyped(json, false);
}

export function SaleInvoiceReadToJSONTyped(
  value?: SaleInvoiceRead | null,
  ignoreDiscriminator: boolean = false,
): any {
  if (value == null) {
    return value;
  }

  return {
    date: value["date"].toISOString().substring(0, 10),
    due_date: value["dueDate"].toISOString().substring(0, 10),
    currency: CurrencyToJSON(value["currency"]),
    pdf_object_id: value["pdfObjectId"],
    customer_user_id: value["customerUserId"],
    bike_benefit_order_id: value["bikeBenefitOrderId"],
    bike_benefit_contract_id: value["bikeBenefitContractId"],
    invoice_number_prefix: value["invoiceNumberPrefix"],
    invoice_number_serial: value["invoiceNumberSerial"],
    vat: value["vat"],
    total: value["total"],
    customer_full_name: value["customerFullName"],
    customer_address: value["customerAddress"],
    customer_post_code: value["customerPostCode"],
    customer_city: value["customerCity"],
    customer_country: SupportedCountriesEnumToJSON(value["customerCountry"]),
    reverts_invoice_id: value["revertsInvoiceId"],
    id: value["id"],
    created_at:
      value["createdAt"] == null ? undefined : value["createdAt"].toISOString(),
    updated_at:
      value["updatedAt"] == null ? undefined : value["updatedAt"].toISOString(),
    invoice_number: value["invoiceNumber"],
    balance: value["balance"],
    status: SaleInvoiceStatusToJSON(value["status"]),
    sale_invoice_lines: (value["saleInvoiceLines"] as Array<any>).map(
      SaleInvoiceLineToJSON,
    ),
    pdf_object: StorageObjectReadToJSON(value["pdfObject"]),
    sale_invoice_transactions: (
      value["saleInvoiceTransactions"] as Array<any>
    ).map(SaleInvoiceTransactionToJSON),
    vapaus_code: value["vapausCode"],
  };
}
