/* tslint:disable */

/* eslint-disable */

/**
 * Vapaus
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { mapValues } from "../runtime";
import type { ProductTypes } from "./ProductTypes";
import {
  ProductTypesFromJSON,
  ProductTypesFromJSONTyped,
  ProductTypesToJSON,
  ProductTypesToJSONTyped,
} from "./ProductTypes";
import type { SupportedOrganisationCountries } from "./SupportedOrganisationCountries";
import {
  SupportedOrganisationCountriesFromJSON,
  SupportedOrganisationCountriesFromJSONTyped,
  SupportedOrganisationCountriesToJSON,
  SupportedOrganisationCountriesToJSONTyped,
} from "./SupportedOrganisationCountries";

/**
 *
 * @export
 * @interface ProductVatRateBase
 */
export interface ProductVatRateBase {
  /**
   * Two letters ISO 3166 country code
   * @type {SupportedOrganisationCountries}
   * @memberof ProductVatRateBase
   */
  country: SupportedOrganisationCountries;
  /**
   *
   * @type {ProductTypes}
   * @memberof ProductVatRateBase
   */
  product: ProductTypes;
  /**
   *
   * @type {number}
   * @memberof ProductVatRateBase
   */
  vatRate: number;
  /**
   *
   * @type {Date}
   * @memberof ProductVatRateBase
   */
  validFrom: Date;
}

/**
 * Check if a given object implements the ProductVatRateBase interface.
 */
export function instanceOfProductVatRateBase(
  value: object,
): value is ProductVatRateBase {
  if (!("country" in value) || value["country"] === undefined) return false;
  if (!("product" in value) || value["product"] === undefined) return false;
  if (!("vatRate" in value) || value["vatRate"] === undefined) return false;
  if (!("validFrom" in value) || value["validFrom"] === undefined) return false;
  return true;
}

export function ProductVatRateBaseFromJSON(json: any): ProductVatRateBase {
  return ProductVatRateBaseFromJSONTyped(json, false);
}

export function ProductVatRateBaseFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): ProductVatRateBase {
  if (json == null) {
    return json;
  }
  return {
    country: SupportedOrganisationCountriesFromJSON(json["country"]),
    product: ProductTypesFromJSON(json["product"]),
    vatRate: json["vat_rate"],
    validFrom: new Date(json["valid_from"]),
  };
}

export function ProductVatRateBaseToJSON(json: any): ProductVatRateBase {
  return ProductVatRateBaseToJSONTyped(json, false);
}

export function ProductVatRateBaseToJSONTyped(
  value?: ProductVatRateBase | null,
  ignoreDiscriminator: boolean = false,
): any {
  if (value == null) {
    return value;
  }

  return {
    country: SupportedOrganisationCountriesToJSON(value["country"]),
    product: ProductTypesToJSON(value["product"]),
    vat_rate: value["vatRate"],
    valid_from: value["validFrom"].toISOString().substring(0, 10),
  };
}
