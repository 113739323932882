import { useTranslation } from "react-i18next";

import {
  Card,
  EmailField,
  HorizontalLine,
  PhoneField,
  Typography,
} from "@vapaus/ui-v2";

export function ContactUsCardDefault() {
  const { t } = useTranslation();

  return (
    <Card title={t("shopV2:support.contact.heading")}>
      <Typography marginBottom="sm">
        {t("shopV2:support.contact.support.line1")}
      </Typography>
      <Typography marginBottom="sm">
        {t("shopV2:support.contact.support.line2")}
      </Typography>
      <PhoneField phoneNumber="+358 94 272 7258" marginBottom="sm" />
      <EmailField email="partners@vapaus.io" />
      <HorizontalLine />
      <Typography marginBottom="sm">
        {t("shopV2:support.contact.partners.line1")}
      </Typography>
      <Typography marginBottom="sm">
        {t("shopV2:support.contact.partners.line2")}
      </Typography>
      <PhoneField phoneNumber="+358 40 176 8008" marginBottom="sm" />
      <EmailField email="partners@vapaus.io" />
    </Card>
  );
}
