/* tslint:disable */

/* eslint-disable */

/**
 * Vapaus
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { mapValues } from "../runtime";

/**
 *
 * @export
 * @interface BikeBenefitOrderDeliveryTransport
 */
export interface BikeBenefitOrderDeliveryTransport {
  /**
   *
   * @type {Date}
   * @memberof BikeBenefitOrderDeliveryTransport
   */
  expectedDeliveryDate?: Date;
  /**
   *
   * @type {string}
   * @memberof BikeBenefitOrderDeliveryTransport
   */
  trackingUrl?: string;
  /**
   *
   * @type {number}
   * @memberof BikeBenefitOrderDeliveryTransport
   */
  priceGross: number;
  /**
   *
   * @type {number}
   * @memberof BikeBenefitOrderDeliveryTransport
   */
  vatRate: number;
}

/**
 * Check if a given object implements the BikeBenefitOrderDeliveryTransport interface.
 */
export function instanceOfBikeBenefitOrderDeliveryTransport(
  value: object,
): value is BikeBenefitOrderDeliveryTransport {
  if (!("priceGross" in value) || value["priceGross"] === undefined)
    return false;
  if (!("vatRate" in value) || value["vatRate"] === undefined) return false;
  return true;
}

export function BikeBenefitOrderDeliveryTransportFromJSON(
  json: any,
): BikeBenefitOrderDeliveryTransport {
  return BikeBenefitOrderDeliveryTransportFromJSONTyped(json, false);
}

export function BikeBenefitOrderDeliveryTransportFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): BikeBenefitOrderDeliveryTransport {
  if (json == null) {
    return json;
  }
  return {
    expectedDeliveryDate:
      json["expected_delivery_date"] == null
        ? undefined
        : new Date(json["expected_delivery_date"]),
    trackingUrl:
      json["tracking_url"] == null ? undefined : json["tracking_url"],
    priceGross: json["price_gross"],
    vatRate: json["vat_rate"],
  };
}

export function BikeBenefitOrderDeliveryTransportToJSON(
  json: any,
): BikeBenefitOrderDeliveryTransport {
  return BikeBenefitOrderDeliveryTransportToJSONTyped(json, false);
}

export function BikeBenefitOrderDeliveryTransportToJSONTyped(
  value?: BikeBenefitOrderDeliveryTransport | null,
  ignoreDiscriminator: boolean = false,
): any {
  if (value == null) {
    return value;
  }

  return {
    expected_delivery_date:
      value["expectedDeliveryDate"] == null
        ? undefined
        : value["expectedDeliveryDate"].toISOString().substring(0, 10),
    tracking_url: value["trackingUrl"],
    price_gross: value["priceGross"],
    vat_rate: value["vatRate"],
  };
}
