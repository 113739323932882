import { useTranslation } from "react-i18next";

import { PageRoot } from "@vapaus/common-v2";
import { Button, Card, CardRow, Flex, Typography } from "@vapaus/ui-v2";

import { ContactUsCard } from "./components/ContactUsCard";

export function SupportPage() {
  const { t } = useTranslation();

  const handleOpenLink = (link: string) => () => {
    window.open(link, "_blank");
  };

  return (
    <PageRoot>
      <Flex direction="column" gap="lg">
        <Flex direction="column" gap="sm">
          <Typography variant="h1" xsAlign="center">
            {t("shopV2:support.heading")}
          </Typography>
          <Typography variant="bodyLg" xsAlign="center">
            {t("shopV2:support.description")}
          </Typography>
        </Flex>
        <Card title={t("shopV2:support.guides.heading")}>
          <CardRow
            title={t("shopV2:support.guides.ordering.title")}
            description={t("shopV2:support.guides.ordering.description")}
            action={
              <Button
                size="small"
                xsFullWidth
                onClick={handleOpenLink(
                  t("shopV2:support.guides.ordering.link"),
                )}
              >
                {t("shopV2:support.guides.ordering.action")}
              </Button>
            }
            separator
          />
          <CardRow
            title={t("shopV2:support.guides.invoicing.title")}
            description={t("shopV2:support.guides.invoicing.description")}
            action={
              <Button
                size="small"
                xsFullWidth
                onClick={handleOpenLink(
                  t("shopV2:support.guides.invoicing.link"),
                )}
              >
                {t("shopV2:support.guides.invoicing.action")}
              </Button>
            }
          />
        </Card>
        <ContactUsCard />
      </Flex>
    </PageRoot>
  );
}
