/* tslint:disable */

/* eslint-disable */

/**
 * Vapaus
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import type {
  AutocompleteResults,
  BikeDataCheckIn,
  BikeDataCheckResult,
  ErrorResponse,
  ErrorWithCodeResponse,
  HTTPValidationError,
} from "../models/index";
import {
  AutocompleteResultsFromJSON,
  AutocompleteResultsToJSON,
  BikeDataCheckInFromJSON,
  BikeDataCheckInToJSON,
  BikeDataCheckResultFromJSON,
  BikeDataCheckResultToJSON,
  ErrorResponseFromJSON,
  ErrorResponseToJSON,
  ErrorWithCodeResponseFromJSON,
  ErrorWithCodeResponseToJSON,
  HTTPValidationErrorFromJSON,
  HTTPValidationErrorToJSON,
} from "../models/index";
import * as runtime from "../runtime";

export interface ShopBikeCheckBikeDataRequest {
  bikeDataCheckIn: BikeDataCheckIn;
}

export interface ShopBikeGetBikeFieldSuggestionsRequest {
  fieldName: ShopBikeGetBikeFieldSuggestionsFieldNameEnum;
  q: string;
}

/**
 *
 */
export class ShopBikeApi extends runtime.BaseAPI {
  /**
   * Check Bike Data
   */
  async shopBikeCheckBikeDataRaw(
    requestParameters: ShopBikeCheckBikeDataRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<BikeDataCheckResult>> {
    if (requestParameters["bikeDataCheckIn"] == null) {
      throw new runtime.RequiredError(
        "bikeDataCheckIn",
        'Required parameter "bikeDataCheckIn" was null or undefined when calling shopBikeCheckBikeData().',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      headerParameters["Authorization"] = await this.configuration.accessToken(
        "OAuth2PasswordBearer",
        [],
      );
    }

    const response = await this.request(
      {
        path: `/shop-api/bikes/check-data`,
        method: "POST",
        headers: headerParameters,
        query: queryParameters,
        body: BikeDataCheckInToJSON(requestParameters["bikeDataCheckIn"]),
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      BikeDataCheckResultFromJSON(jsonValue),
    );
  }

  /**
   * Check Bike Data
   */
  async shopBikeCheckBikeData(
    requestParameters: ShopBikeCheckBikeDataRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<BikeDataCheckResult> {
    const response = await this.shopBikeCheckBikeDataRaw(
      requestParameters,
      initOverrides,
    );
    return await response.value();
  }

  /**
   * Get Bike Field Suggestions
   */
  async shopBikeGetBikeFieldSuggestionsRaw(
    requestParameters: ShopBikeGetBikeFieldSuggestionsRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<AutocompleteResults>> {
    if (requestParameters["fieldName"] == null) {
      throw new runtime.RequiredError(
        "fieldName",
        'Required parameter "fieldName" was null or undefined when calling shopBikeGetBikeFieldSuggestions().',
      );
    }

    if (requestParameters["q"] == null) {
      throw new runtime.RequiredError(
        "q",
        'Required parameter "q" was null or undefined when calling shopBikeGetBikeFieldSuggestions().',
      );
    }

    const queryParameters: any = {};

    if (requestParameters["fieldName"] != null) {
      queryParameters["field_name"] = requestParameters["fieldName"];
    }

    if (requestParameters["q"] != null) {
      queryParameters["q"] = requestParameters["q"];
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      headerParameters["Authorization"] = await this.configuration.accessToken(
        "OAuth2PasswordBearer",
        [],
      );
    }

    const response = await this.request(
      {
        path: `/shop-api/bikes/field-suggestions`,
        method: "GET",
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      AutocompleteResultsFromJSON(jsonValue),
    );
  }

  /**
   * Get Bike Field Suggestions
   */
  async shopBikeGetBikeFieldSuggestions(
    requestParameters: ShopBikeGetBikeFieldSuggestionsRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<AutocompleteResults> {
    const response = await this.shopBikeGetBikeFieldSuggestionsRaw(
      requestParameters,
      initOverrides,
    );
    return await response.value();
  }
}

/**
 * @export
 */
export const ShopBikeGetBikeFieldSuggestionsFieldNameEnum = {
  Manufacturer: "manufacturer",
  Model: "model",
} as const;
export type ShopBikeGetBikeFieldSuggestionsFieldNameEnum =
  (typeof ShopBikeGetBikeFieldSuggestionsFieldNameEnum)[keyof typeof ShopBikeGetBikeFieldSuggestionsFieldNameEnum];
