/* tslint:disable */

/* eslint-disable */

/**
 * Vapaus
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { mapValues } from "../runtime";
import type { WorkTypes } from "./WorkTypes";
import {
  WorkTypesFromJSON,
  WorkTypesFromJSONTyped,
  WorkTypesToJSON,
  WorkTypesToJSONTyped,
} from "./WorkTypes";

/**
 *
 * @export
 * @interface ShopMaintenanceEventUpdate
 */
export interface ShopMaintenanceEventUpdate {
  /**
   *
   * @type {number}
   * @memberof ShopMaintenanceEventUpdate
   */
  partsPrice: number;
  /**
   *
   * @type {number}
   * @memberof ShopMaintenanceEventUpdate
   */
  workPrice: number;
  /**
   *
   * @type {WorkTypes}
   * @memberof ShopMaintenanceEventUpdate
   */
  workType: WorkTypes;
  /**
   *
   * @type {string}
   * @memberof ShopMaintenanceEventUpdate
   */
  workDescription: string;
  /**
   *
   * @type {Date}
   * @memberof ShopMaintenanceEventUpdate
   */
  workDate: Date;
}

/**
 * Check if a given object implements the ShopMaintenanceEventUpdate interface.
 */
export function instanceOfShopMaintenanceEventUpdate(
  value: object,
): value is ShopMaintenanceEventUpdate {
  if (!("partsPrice" in value) || value["partsPrice"] === undefined)
    return false;
  if (!("workPrice" in value) || value["workPrice"] === undefined) return false;
  if (!("workType" in value) || value["workType"] === undefined) return false;
  if (!("workDescription" in value) || value["workDescription"] === undefined)
    return false;
  if (!("workDate" in value) || value["workDate"] === undefined) return false;
  return true;
}

export function ShopMaintenanceEventUpdateFromJSON(
  json: any,
): ShopMaintenanceEventUpdate {
  return ShopMaintenanceEventUpdateFromJSONTyped(json, false);
}

export function ShopMaintenanceEventUpdateFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): ShopMaintenanceEventUpdate {
  if (json == null) {
    return json;
  }
  return {
    partsPrice: json["parts_price"],
    workPrice: json["work_price"],
    workType: WorkTypesFromJSON(json["work_type"]),
    workDescription: json["work_description"],
    workDate: new Date(json["work_date"]),
  };
}

export function ShopMaintenanceEventUpdateToJSON(
  json: any,
): ShopMaintenanceEventUpdate {
  return ShopMaintenanceEventUpdateToJSONTyped(json, false);
}

export function ShopMaintenanceEventUpdateToJSONTyped(
  value?: ShopMaintenanceEventUpdate | null,
  ignoreDiscriminator: boolean = false,
): any {
  if (value == null) {
    return value;
  }

  return {
    parts_price: value["partsPrice"],
    work_price: value["workPrice"],
    work_type: WorkTypesToJSON(value["workType"]),
    work_description: value["workDescription"],
    work_date: value["workDate"].toISOString().substring(0, 10),
  };
}
