/* tslint:disable */

/* eslint-disable */

/**
 * Vapaus
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { mapValues } from "../runtime";
import type { AccessoryType } from "./AccessoryType";
import {
  AccessoryTypeFromJSON,
  AccessoryTypeFromJSONTyped,
  AccessoryTypeToJSON,
  AccessoryTypeToJSONTyped,
} from "./AccessoryType";
import type { SupportedOrganisationCountries } from "./SupportedOrganisationCountries";
import {
  SupportedOrganisationCountriesFromJSON,
  SupportedOrganisationCountriesFromJSONTyped,
  SupportedOrganisationCountriesToJSON,
  SupportedOrganisationCountriesToJSONTyped,
} from "./SupportedOrganisationCountries";

/**
 *
 * @export
 * @interface AccessoryTypeRead
 */
export interface AccessoryTypeRead {
  /**
   *
   * @type {AccessoryType}
   * @memberof AccessoryTypeRead
   */
  accessoryType: AccessoryType;
  /**
   *
   * @type {Array<SupportedOrganisationCountries>}
   * @memberof AccessoryTypeRead
   */
  availabilities: Array<SupportedOrganisationCountries>;
}

/**
 * Check if a given object implements the AccessoryTypeRead interface.
 */
export function instanceOfAccessoryTypeRead(
  value: object,
): value is AccessoryTypeRead {
  if (!("accessoryType" in value) || value["accessoryType"] === undefined)
    return false;
  if (!("availabilities" in value) || value["availabilities"] === undefined)
    return false;
  return true;
}

export function AccessoryTypeReadFromJSON(json: any): AccessoryTypeRead {
  return AccessoryTypeReadFromJSONTyped(json, false);
}

export function AccessoryTypeReadFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): AccessoryTypeRead {
  if (json == null) {
    return json;
  }
  return {
    accessoryType: AccessoryTypeFromJSON(json["accessory_type"]),
    availabilities: (json["availabilities"] as Array<any>).map(
      SupportedOrganisationCountriesFromJSON,
    ),
  };
}

export function AccessoryTypeReadToJSON(json: any): AccessoryTypeRead {
  return AccessoryTypeReadToJSONTyped(json, false);
}

export function AccessoryTypeReadToJSONTyped(
  value?: AccessoryTypeRead | null,
  ignoreDiscriminator: boolean = false,
): any {
  if (value == null) {
    return value;
  }

  return {
    accessory_type: AccessoryTypeToJSON(value["accessoryType"]),
    availabilities: (value["availabilities"] as Array<any>).map(
      SupportedOrganisationCountriesToJSON,
    ),
  };
}
