/* tslint:disable */

/* eslint-disable */

/**
 * Vapaus
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { mapValues } from "../runtime";
import type { AccessoryCreateUpdate } from "./AccessoryCreateUpdate";
import {
  AccessoryCreateUpdateFromJSON,
  AccessoryCreateUpdateFromJSONTyped,
  AccessoryCreateUpdateToJSON,
  AccessoryCreateUpdateToJSONTyped,
} from "./AccessoryCreateUpdate";
import type { BikeBenefitOrderAdditionalServiceWrite } from "./BikeBenefitOrderAdditionalServiceWrite";
import {
  BikeBenefitOrderAdditionalServiceWriteFromJSON,
  BikeBenefitOrderAdditionalServiceWriteFromJSONTyped,
  BikeBenefitOrderAdditionalServiceWriteToJSON,
  BikeBenefitOrderAdditionalServiceWriteToJSONTyped,
} from "./BikeBenefitOrderAdditionalServiceWrite";
import type { BikeBenefitOrderDeliveryTransport } from "./BikeBenefitOrderDeliveryTransport";
import {
  BikeBenefitOrderDeliveryTransportFromJSON,
  BikeBenefitOrderDeliveryTransportFromJSONTyped,
  BikeBenefitOrderDeliveryTransportToJSON,
  BikeBenefitOrderDeliveryTransportToJSONTyped,
} from "./BikeBenefitOrderDeliveryTransport";
import type { BikeCreateUpdate } from "./BikeCreateUpdate";
import {
  BikeCreateUpdateFromJSON,
  BikeCreateUpdateFromJSONTyped,
  BikeCreateUpdateToJSON,
  BikeCreateUpdateToJSONTyped,
} from "./BikeCreateUpdate";

/**
 *
 * @export
 * @interface BikeBenefitOrderCreateUpdate
 */
export interface BikeBenefitOrderCreateUpdate {
  /**
   *
   * @type {string}
   * @memberof BikeBenefitOrderCreateUpdate
   */
  userId: string;
  /**
   *
   * @type {string}
   * @memberof BikeBenefitOrderCreateUpdate
   */
  benefitDefinitionId: string;
  /**
   *
   * @type {string}
   * @memberof BikeBenefitOrderCreateUpdate
   */
  benefitDefinitionTermsId: string;
  /**
   *
   * @type {number}
   * @memberof BikeBenefitOrderCreateUpdate
   */
  leasingPeriodMonths: number;
  /**
   *
   * @type {string}
   * @memberof BikeBenefitOrderCreateUpdate
   */
  orderNumber?: string;
  /**
   *
   * @type {BikeCreateUpdate}
   * @memberof BikeBenefitOrderCreateUpdate
   */
  bike?: BikeCreateUpdate;
  /**
   *
   * @type {string}
   * @memberof BikeBenefitOrderCreateUpdate
   */
  bikeId?: string;
  /**
   *
   * @type {number}
   * @memberof BikeBenefitOrderCreateUpdate
   */
  netBikePrice: number;
  /**
   *
   * @type {Array<AccessoryCreateUpdate>}
   * @memberof BikeBenefitOrderCreateUpdate
   */
  accessories: Array<AccessoryCreateUpdate>;
  /**
   *
   * @type {number}
   * @memberof BikeBenefitOrderCreateUpdate
   */
  fixedDownPaymentAmount?: number;
  /**
   *
   * @type {number}
   * @memberof BikeBenefitOrderCreateUpdate
   */
  fixedMonthlyTaxableValue?: number;
  /**
   *
   * @type {number}
   * @memberof BikeBenefitOrderCreateUpdate
   */
  monthlyMaintenanceBudget: number;
  /**
   *
   * @type {boolean}
   * @memberof BikeBenefitOrderCreateUpdate
   */
  hasExtendedWarranty?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof BikeBenefitOrderCreateUpdate
   */
  isDownPaymentInsured?: boolean;
  /**
   *
   * @type {string}
   * @memberof BikeBenefitOrderCreateUpdate
   */
  notes?: string;
  /**
   *
   * @type {BikeBenefitOrderDeliveryTransport}
   * @memberof BikeBenefitOrderCreateUpdate
   */
  deliveryTransport?: BikeBenefitOrderDeliveryTransport;
  /**
   *
   * @type {Array<BikeBenefitOrderAdditionalServiceWrite>}
   * @memberof BikeBenefitOrderCreateUpdate
   */
  additionalServices: Array<BikeBenefitOrderAdditionalServiceWrite>;
}

/**
 * Check if a given object implements the BikeBenefitOrderCreateUpdate interface.
 */
export function instanceOfBikeBenefitOrderCreateUpdate(
  value: object,
): value is BikeBenefitOrderCreateUpdate {
  if (!("userId" in value) || value["userId"] === undefined) return false;
  if (
    !("benefitDefinitionId" in value) ||
    value["benefitDefinitionId"] === undefined
  )
    return false;
  if (
    !("benefitDefinitionTermsId" in value) ||
    value["benefitDefinitionTermsId"] === undefined
  )
    return false;
  if (
    !("leasingPeriodMonths" in value) ||
    value["leasingPeriodMonths"] === undefined
  )
    return false;
  if (!("netBikePrice" in value) || value["netBikePrice"] === undefined)
    return false;
  if (!("accessories" in value) || value["accessories"] === undefined)
    return false;
  if (
    !("monthlyMaintenanceBudget" in value) ||
    value["monthlyMaintenanceBudget"] === undefined
  )
    return false;
  if (
    !("additionalServices" in value) ||
    value["additionalServices"] === undefined
  )
    return false;
  return true;
}

export function BikeBenefitOrderCreateUpdateFromJSON(
  json: any,
): BikeBenefitOrderCreateUpdate {
  return BikeBenefitOrderCreateUpdateFromJSONTyped(json, false);
}

export function BikeBenefitOrderCreateUpdateFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): BikeBenefitOrderCreateUpdate {
  if (json == null) {
    return json;
  }
  return {
    userId: json["user_id"],
    benefitDefinitionId: json["benefit_definition_id"],
    benefitDefinitionTermsId: json["benefit_definition_terms_id"],
    leasingPeriodMonths: json["leasing_period_months"],
    orderNumber:
      json["order_number"] == null ? undefined : json["order_number"],
    bike:
      json["bike"] == null ? undefined : BikeCreateUpdateFromJSON(json["bike"]),
    bikeId: json["bike_id"] == null ? undefined : json["bike_id"],
    netBikePrice: json["net_bike_price"],
    accessories: (json["accessories"] as Array<any>).map(
      AccessoryCreateUpdateFromJSON,
    ),
    fixedDownPaymentAmount:
      json["fixed_down_payment_amount"] == null
        ? undefined
        : json["fixed_down_payment_amount"],
    fixedMonthlyTaxableValue:
      json["fixed_monthly_taxable_value"] == null
        ? undefined
        : json["fixed_monthly_taxable_value"],
    monthlyMaintenanceBudget: json["monthly_maintenance_budget"],
    hasExtendedWarranty:
      json["has_extended_warranty"] == null
        ? undefined
        : json["has_extended_warranty"],
    isDownPaymentInsured:
      json["is_down_payment_insured"] == null
        ? undefined
        : json["is_down_payment_insured"],
    notes: json["notes"] == null ? undefined : json["notes"],
    deliveryTransport:
      json["delivery_transport"] == null
        ? undefined
        : BikeBenefitOrderDeliveryTransportFromJSON(json["delivery_transport"]),
    additionalServices: (json["additional_services"] as Array<any>).map(
      BikeBenefitOrderAdditionalServiceWriteFromJSON,
    ),
  };
}

export function BikeBenefitOrderCreateUpdateToJSON(
  json: any,
): BikeBenefitOrderCreateUpdate {
  return BikeBenefitOrderCreateUpdateToJSONTyped(json, false);
}

export function BikeBenefitOrderCreateUpdateToJSONTyped(
  value?: BikeBenefitOrderCreateUpdate | null,
  ignoreDiscriminator: boolean = false,
): any {
  if (value == null) {
    return value;
  }

  return {
    user_id: value["userId"],
    benefit_definition_id: value["benefitDefinitionId"],
    benefit_definition_terms_id: value["benefitDefinitionTermsId"],
    leasing_period_months: value["leasingPeriodMonths"],
    order_number: value["orderNumber"],
    bike: BikeCreateUpdateToJSON(value["bike"]),
    bike_id: value["bikeId"],
    net_bike_price: value["netBikePrice"],
    accessories: (value["accessories"] as Array<any>).map(
      AccessoryCreateUpdateToJSON,
    ),
    fixed_down_payment_amount: value["fixedDownPaymentAmount"],
    fixed_monthly_taxable_value: value["fixedMonthlyTaxableValue"],
    monthly_maintenance_budget: value["monthlyMaintenanceBudget"],
    has_extended_warranty: value["hasExtendedWarranty"],
    is_down_payment_insured: value["isDownPaymentInsured"],
    notes: value["notes"],
    delivery_transport: BikeBenefitOrderDeliveryTransportToJSON(
      value["deliveryTransport"],
    ),
    additional_services: (value["additionalServices"] as Array<any>).map(
      BikeBenefitOrderAdditionalServiceWriteToJSON,
    ),
  };
}
