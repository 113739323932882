/* tslint:disable */

/* eslint-disable */

/**
 * Vapaus
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { mapValues } from "../runtime";
import type { ShopBikeBrand } from "./ShopBikeBrand";
import {
  ShopBikeBrandFromJSON,
  ShopBikeBrandFromJSONTyped,
  ShopBikeBrandToJSON,
  ShopBikeBrandToJSONTyped,
} from "./ShopBikeBrand";

/**
 *
 * @export
 * @interface PaginatedShopBikeBrandOut
 */
export interface PaginatedShopBikeBrandOut {
  /**
   *
   * @type {number}
   * @memberof PaginatedShopBikeBrandOut
   */
  total: number;
  /**
   *
   * @type {Array<ShopBikeBrand>}
   * @memberof PaginatedShopBikeBrandOut
   */
  items: Array<ShopBikeBrand>;
}

/**
 * Check if a given object implements the PaginatedShopBikeBrandOut interface.
 */
export function instanceOfPaginatedShopBikeBrandOut(
  value: object,
): value is PaginatedShopBikeBrandOut {
  if (!("total" in value) || value["total"] === undefined) return false;
  if (!("items" in value) || value["items"] === undefined) return false;
  return true;
}

export function PaginatedShopBikeBrandOutFromJSON(
  json: any,
): PaginatedShopBikeBrandOut {
  return PaginatedShopBikeBrandOutFromJSONTyped(json, false);
}

export function PaginatedShopBikeBrandOutFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): PaginatedShopBikeBrandOut {
  if (json == null) {
    return json;
  }
  return {
    total: json["total"],
    items: (json["items"] as Array<any>).map(ShopBikeBrandFromJSON),
  };
}

export function PaginatedShopBikeBrandOutToJSON(
  json: any,
): PaginatedShopBikeBrandOut {
  return PaginatedShopBikeBrandOutToJSONTyped(json, false);
}

export function PaginatedShopBikeBrandOutToJSONTyped(
  value?: PaginatedShopBikeBrandOut | null,
  ignoreDiscriminator: boolean = false,
): any {
  if (value == null) {
    return value;
  }

  return {
    total: value["total"],
    items: (value["items"] as Array<any>).map(ShopBikeBrandToJSON),
  };
}
