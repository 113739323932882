/* tslint:disable */

/* eslint-disable */

/**
 * Vapaus
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { mapValues } from "../runtime";
import type { SaleInvoiceLineType } from "./SaleInvoiceLineType";
import {
  SaleInvoiceLineTypeFromJSON,
  SaleInvoiceLineTypeFromJSONTyped,
  SaleInvoiceLineTypeToJSON,
  SaleInvoiceLineTypeToJSONTyped,
} from "./SaleInvoiceLineType";

/**
 *
 * @export
 * @interface SaleInvoiceLine
 */
export interface SaleInvoiceLine {
  /**
   *
   * @type {SaleInvoiceLineType}
   * @memberof SaleInvoiceLine
   */
  type: SaleInvoiceLineType;
  /**
   *
   * @type {string}
   * @memberof SaleInvoiceLine
   */
  description: string;
  /**
   *
   * @type {number}
   * @memberof SaleInvoiceLine
   */
  price: number;
  /**
   *
   * @type {number}
   * @memberof SaleInvoiceLine
   */
  quantity: number;
  /**
   *
   * @type {number}
   * @memberof SaleInvoiceLine
   */
  vatRate: number;
  /**
   *
   * @type {string}
   * @memberof SaleInvoiceLine
   */
  saleInvoiceId: string;
  /**
   *
   * @type {number}
   * @memberof SaleInvoiceLine
   */
  subtotal: number;
  /**
   *
   * @type {number}
   * @memberof SaleInvoiceLine
   */
  vat: number;
  /**
   *
   * @type {number}
   * @memberof SaleInvoiceLine
   */
  total: number;
  /**
   *
   * @type {string}
   * @memberof SaleInvoiceLine
   */
  id?: string;
  /**
   *
   * @type {Date}
   * @memberof SaleInvoiceLine
   */
  createdAt?: Date;
  /**
   *
   * @type {Date}
   * @memberof SaleInvoiceLine
   */
  updatedAt?: Date;
}

/**
 * Check if a given object implements the SaleInvoiceLine interface.
 */
export function instanceOfSaleInvoiceLine(
  value: object,
): value is SaleInvoiceLine {
  if (!("type" in value) || value["type"] === undefined) return false;
  if (!("description" in value) || value["description"] === undefined)
    return false;
  if (!("price" in value) || value["price"] === undefined) return false;
  if (!("quantity" in value) || value["quantity"] === undefined) return false;
  if (!("vatRate" in value) || value["vatRate"] === undefined) return false;
  if (!("saleInvoiceId" in value) || value["saleInvoiceId"] === undefined)
    return false;
  if (!("subtotal" in value) || value["subtotal"] === undefined) return false;
  if (!("vat" in value) || value["vat"] === undefined) return false;
  if (!("total" in value) || value["total"] === undefined) return false;
  return true;
}

export function SaleInvoiceLineFromJSON(json: any): SaleInvoiceLine {
  return SaleInvoiceLineFromJSONTyped(json, false);
}

export function SaleInvoiceLineFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): SaleInvoiceLine {
  if (json == null) {
    return json;
  }
  return {
    type: SaleInvoiceLineTypeFromJSON(json["type"]),
    description: json["description"],
    price: json["price"],
    quantity: json["quantity"],
    vatRate: json["vat_rate"],
    saleInvoiceId: json["sale_invoice_id"],
    subtotal: json["subtotal"],
    vat: json["vat"],
    total: json["total"],
    id: json["id"] == null ? undefined : json["id"],
    createdAt:
      json["created_at"] == null ? undefined : new Date(json["created_at"]),
    updatedAt:
      json["updated_at"] == null ? undefined : new Date(json["updated_at"]),
  };
}

export function SaleInvoiceLineToJSON(json: any): SaleInvoiceLine {
  return SaleInvoiceLineToJSONTyped(json, false);
}

export function SaleInvoiceLineToJSONTyped(
  value?: SaleInvoiceLine | null,
  ignoreDiscriminator: boolean = false,
): any {
  if (value == null) {
    return value;
  }

  return {
    type: SaleInvoiceLineTypeToJSON(value["type"]),
    description: value["description"],
    price: value["price"],
    quantity: value["quantity"],
    vat_rate: value["vatRate"],
    sale_invoice_id: value["saleInvoiceId"],
    subtotal: value["subtotal"],
    vat: value["vat"],
    total: value["total"],
    id: value["id"],
    created_at:
      value["createdAt"] == null ? undefined : value["createdAt"].toISOString(),
    updated_at:
      value["updatedAt"] == null ? undefined : value["updatedAt"].toISOString(),
  };
}
