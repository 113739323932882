/* tslint:disable */

/* eslint-disable */

/**
 * Vapaus
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { mapValues } from "../runtime";

/**
 *
 * @export
 * @interface ShopBikeBrandWrite
 */
export interface ShopBikeBrandWrite {
  /**
   *
   * @type {string}
   * @memberof ShopBikeBrandWrite
   */
  shopId: string;
  /**
   *
   * @type {string}
   * @memberof ShopBikeBrandWrite
   */
  bikeBrandId: string;
}

/**
 * Check if a given object implements the ShopBikeBrandWrite interface.
 */
export function instanceOfShopBikeBrandWrite(
  value: object,
): value is ShopBikeBrandWrite {
  if (!("shopId" in value) || value["shopId"] === undefined) return false;
  if (!("bikeBrandId" in value) || value["bikeBrandId"] === undefined)
    return false;
  return true;
}

export function ShopBikeBrandWriteFromJSON(json: any): ShopBikeBrandWrite {
  return ShopBikeBrandWriteFromJSONTyped(json, false);
}

export function ShopBikeBrandWriteFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): ShopBikeBrandWrite {
  if (json == null) {
    return json;
  }
  return {
    shopId: json["shop_id"],
    bikeBrandId: json["bike_brand_id"],
  };
}

export function ShopBikeBrandWriteToJSON(json: any): ShopBikeBrandWrite {
  return ShopBikeBrandWriteToJSONTyped(json, false);
}

export function ShopBikeBrandWriteToJSONTyped(
  value?: ShopBikeBrandWrite | null,
  ignoreDiscriminator: boolean = false,
): any {
  if (value == null) {
    return value;
  }

  return {
    shop_id: value["shopId"],
    bike_brand_id: value["bikeBrandId"],
  };
}
