/* tslint:disable */

/* eslint-disable */

/**
 * Vapaus
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import type {
  BenefitDefinitionReadWithOrganisationSummary,
  ErrorResponse,
  ErrorWithCodeResponse,
  HTTPValidationError,
  OrderDirection,
  PaginatedBenefitDefinitionSummaryOut,
} from "../models/index";
import {
  BenefitDefinitionReadWithOrganisationSummaryFromJSON,
  BenefitDefinitionReadWithOrganisationSummaryToJSON,
  ErrorResponseFromJSON,
  ErrorResponseToJSON,
  ErrorWithCodeResponseFromJSON,
  ErrorWithCodeResponseToJSON,
  HTTPValidationErrorFromJSON,
  HTTPValidationErrorToJSON,
  OrderDirectionFromJSON,
  OrderDirectionToJSON,
  PaginatedBenefitDefinitionSummaryOutFromJSON,
  PaginatedBenefitDefinitionSummaryOutToJSON,
} from "../models/index";
import * as runtime from "../runtime";

export interface ShopBenefitDefinitionsGetBenefitDefinitionRequest {
  benefitId: string;
}

export interface ShopBenefitDefinitionsSearchBenefitDefinitionRequest {
  organisationId?: string;
  q?: string;
  ids?: Array<string>;
  skip?: number;
  limit?: number;
  sortField?: string;
  sortDirection?: OrderDirection;
  xFilterIds?: string;
}

/**
 *
 */
export class ShopBenefitDefinitionsApi extends runtime.BaseAPI {
  /**
   * Get Benefit Definition
   */
  async shopBenefitDefinitionsGetBenefitDefinitionRaw(
    requestParameters: ShopBenefitDefinitionsGetBenefitDefinitionRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<
    runtime.ApiResponse<BenefitDefinitionReadWithOrganisationSummary>
  > {
    if (requestParameters["benefitId"] == null) {
      throw new runtime.RequiredError(
        "benefitId",
        'Required parameter "benefitId" was null or undefined when calling shopBenefitDefinitionsGetBenefitDefinition().',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      headerParameters["Authorization"] = await this.configuration.accessToken(
        "OAuth2PasswordBearer",
        [],
      );
    }

    const response = await this.request(
      {
        path: `/shop-api/benefit-definitions/{benefit_id}`.replace(
          `{${"benefit_id"}}`,
          encodeURIComponent(String(requestParameters["benefitId"])),
        ),
        method: "GET",
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      BenefitDefinitionReadWithOrganisationSummaryFromJSON(jsonValue),
    );
  }

  /**
   * Get Benefit Definition
   */
  async shopBenefitDefinitionsGetBenefitDefinition(
    requestParameters: ShopBenefitDefinitionsGetBenefitDefinitionRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<BenefitDefinitionReadWithOrganisationSummary> {
    const response = await this.shopBenefitDefinitionsGetBenefitDefinitionRaw(
      requestParameters,
      initOverrides,
    );
    return await response.value();
  }

  /**
   * Search Benefit Definition
   */
  async shopBenefitDefinitionsSearchBenefitDefinitionRaw(
    requestParameters: ShopBenefitDefinitionsSearchBenefitDefinitionRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<PaginatedBenefitDefinitionSummaryOut>> {
    const queryParameters: any = {};

    if (requestParameters["organisationId"] != null) {
      queryParameters["organisation_id"] = requestParameters["organisationId"];
    }

    if (requestParameters["q"] != null) {
      queryParameters["q"] = requestParameters["q"];
    }

    if (requestParameters["ids"] != null) {
      queryParameters["ids"] = requestParameters["ids"];
    }

    if (requestParameters["skip"] != null) {
      queryParameters["skip"] = requestParameters["skip"];
    }

    if (requestParameters["limit"] != null) {
      queryParameters["limit"] = requestParameters["limit"];
    }

    if (requestParameters["sortField"] != null) {
      queryParameters["sort_field"] = requestParameters["sortField"];
    }

    if (requestParameters["sortDirection"] != null) {
      queryParameters["sort_direction"] = requestParameters["sortDirection"];
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (requestParameters["xFilterIds"] != null) {
      headerParameters["X-Filter-Ids"] = String(
        requestParameters["xFilterIds"],
      );
    }

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      headerParameters["Authorization"] = await this.configuration.accessToken(
        "OAuth2PasswordBearer",
        [],
      );
    }

    const response = await this.request(
      {
        path: `/shop-api/benefit-definitions`,
        method: "GET",
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      PaginatedBenefitDefinitionSummaryOutFromJSON(jsonValue),
    );
  }

  /**
   * Search Benefit Definition
   */
  async shopBenefitDefinitionsSearchBenefitDefinition(
    requestParameters: ShopBenefitDefinitionsSearchBenefitDefinitionRequest = {},
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<PaginatedBenefitDefinitionSummaryOut> {
    const response =
      await this.shopBenefitDefinitionsSearchBenefitDefinitionRaw(
        requestParameters,
        initOverrides,
      );
    return await response.value();
  }
}
