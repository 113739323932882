import { useTranslation } from "react-i18next";

import { Card, EmailField, PhoneField, Typography } from "@vapaus/ui-v2";

export function ContactUsCardSweden() {
  const { t } = useTranslation();

  return (
    <Card title={t("shopV2:support.contact.heading")}>
      <Typography marginBottom="sm">
        {t("shopV2:support.contact.sweden.partnershipTeam")}
      </Typography>
      <PhoneField phoneNumber="+46 733804987" marginBottom="sm" />
      <EmailField email="cyklar@vapaus.se" />
    </Card>
  );
}
