/* tslint:disable */

/* eslint-disable */

/**
 * Vapaus
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { mapValues } from "../runtime";
import type { BikeDataCheckMessage } from "./BikeDataCheckMessage";
import {
  BikeDataCheckMessageFromJSON,
  BikeDataCheckMessageFromJSONTyped,
  BikeDataCheckMessageToJSON,
  BikeDataCheckMessageToJSONTyped,
} from "./BikeDataCheckMessage";

/**
 *
 * @export
 * @interface BikeDataCheckFieldResult
 */
export interface BikeDataCheckFieldResult {
  /**
   *
   * @type {Array<BikeDataCheckMessage>}
   * @memberof BikeDataCheckFieldResult
   */
  warnings: Array<BikeDataCheckMessage>;
}

/**
 * Check if a given object implements the BikeDataCheckFieldResult interface.
 */
export function instanceOfBikeDataCheckFieldResult(
  value: object,
): value is BikeDataCheckFieldResult {
  if (!("warnings" in value) || value["warnings"] === undefined) return false;
  return true;
}

export function BikeDataCheckFieldResultFromJSON(
  json: any,
): BikeDataCheckFieldResult {
  return BikeDataCheckFieldResultFromJSONTyped(json, false);
}

export function BikeDataCheckFieldResultFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): BikeDataCheckFieldResult {
  if (json == null) {
    return json;
  }
  return {
    warnings: (json["warnings"] as Array<any>).map(
      BikeDataCheckMessageFromJSON,
    ),
  };
}

export function BikeDataCheckFieldResultToJSON(
  json: any,
): BikeDataCheckFieldResult {
  return BikeDataCheckFieldResultToJSONTyped(json, false);
}

export function BikeDataCheckFieldResultToJSONTyped(
  value?: BikeDataCheckFieldResult | null,
  ignoreDiscriminator: boolean = false,
): any {
  if (value == null) {
    return value;
  }

  return {
    warnings: (value["warnings"] as Array<any>).map(BikeDataCheckMessageToJSON),
  };
}
