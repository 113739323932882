/* tslint:disable */

/* eslint-disable */

/**
 * Vapaus
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { mapValues } from "../runtime";
import type { BikeCondition } from "./BikeCondition";
import {
  BikeConditionFromJSON,
  BikeConditionFromJSONTyped,
  BikeConditionToJSON,
  BikeConditionToJSONTyped,
} from "./BikeCondition";
import type { BikeDriveType } from "./BikeDriveType";
import {
  BikeDriveTypeFromJSON,
  BikeDriveTypeFromJSONTyped,
  BikeDriveTypeToJSON,
  BikeDriveTypeToJSONTyped,
} from "./BikeDriveType";
import type { BikeFrameType } from "./BikeFrameType";
import {
  BikeFrameTypeFromJSON,
  BikeFrameTypeFromJSONTyped,
  BikeFrameTypeToJSON,
  BikeFrameTypeToJSONTyped,
} from "./BikeFrameType";
import type { Currency } from "./Currency";
import {
  CurrencyFromJSON,
  CurrencyFromJSONTyped,
  CurrencyToJSON,
  CurrencyToJSONTyped,
} from "./Currency";

/**
 *
 * @export
 * @interface BikeCreateUpdate
 */
export interface BikeCreateUpdate {
  /**
   *
   * @type {string}
   * @memberof BikeCreateUpdate
   */
  serialNumber: string;
  /**
   *
   * @type {string}
   * @memberof BikeCreateUpdate
   */
  manufacturer?: string;
  /**
   *
   * @type {string}
   * @memberof BikeCreateUpdate
   */
  bikeBrandId: string;
  /**
   *
   * @type {string}
   * @memberof BikeCreateUpdate
   */
  model: string;
  /**
   *
   * @type {BikeDriveType}
   * @memberof BikeCreateUpdate
   */
  driveType: BikeDriveType;
  /**
   *
   * @type {BikeFrameType}
   * @memberof BikeCreateUpdate
   */
  frameType: BikeFrameType;
  /**
   *
   * @type {string}
   * @memberof BikeCreateUpdate
   */
  size: string;
  /**
   *
   * @type {string}
   * @memberof BikeCreateUpdate
   */
  colour: string;
  /**
   *
   * @type {number}
   * @memberof BikeCreateUpdate
   */
  purchasePrice: number;
  /**
   *
   * @type {Currency}
   * @memberof BikeCreateUpdate
   */
  purchaseCurrency: Currency;
  /**
   *
   * @type {number}
   * @memberof BikeCreateUpdate
   */
  purchaseVatRate: number;
  /**
   *
   * @type {string}
   * @memberof BikeCreateUpdate
   */
  shopId: string;
  /**
   *
   * @type {BikeCondition}
   * @memberof BikeCreateUpdate
   */
  condition: BikeCondition;
  /**
   *
   * @type {string}
   * @memberof BikeCreateUpdate
   */
  pictureId?: string;
  /**
   *
   * @type {string}
   * @memberof BikeCreateUpdate
   */
  url?: string;
  /**
   * Leave empty in case of creating a Bike
   * @type {string}
   * @memberof BikeCreateUpdate
   */
  id?: string;
}

/**
 * Check if a given object implements the BikeCreateUpdate interface.
 */
export function instanceOfBikeCreateUpdate(
  value: object,
): value is BikeCreateUpdate {
  if (!("serialNumber" in value) || value["serialNumber"] === undefined)
    return false;
  if (!("bikeBrandId" in value) || value["bikeBrandId"] === undefined)
    return false;
  if (!("model" in value) || value["model"] === undefined) return false;
  if (!("driveType" in value) || value["driveType"] === undefined) return false;
  if (!("frameType" in value) || value["frameType"] === undefined) return false;
  if (!("size" in value) || value["size"] === undefined) return false;
  if (!("colour" in value) || value["colour"] === undefined) return false;
  if (!("purchasePrice" in value) || value["purchasePrice"] === undefined)
    return false;
  if (!("purchaseCurrency" in value) || value["purchaseCurrency"] === undefined)
    return false;
  if (!("purchaseVatRate" in value) || value["purchaseVatRate"] === undefined)
    return false;
  if (!("shopId" in value) || value["shopId"] === undefined) return false;
  if (!("condition" in value) || value["condition"] === undefined) return false;
  return true;
}

export function BikeCreateUpdateFromJSON(json: any): BikeCreateUpdate {
  return BikeCreateUpdateFromJSONTyped(json, false);
}

export function BikeCreateUpdateFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): BikeCreateUpdate {
  if (json == null) {
    return json;
  }
  return {
    serialNumber: json["serial_number"],
    manufacturer:
      json["manufacturer"] == null ? undefined : json["manufacturer"],
    bikeBrandId: json["bike_brand_id"],
    model: json["model"],
    driveType: BikeDriveTypeFromJSON(json["drive_type"]),
    frameType: BikeFrameTypeFromJSON(json["frame_type"]),
    size: json["size"],
    colour: json["colour"],
    purchasePrice: json["purchase_price"],
    purchaseCurrency: CurrencyFromJSON(json["purchase_currency"]),
    purchaseVatRate: json["purchase_vat_rate"],
    shopId: json["shop_id"],
    condition: BikeConditionFromJSON(json["condition"]),
    pictureId: json["picture_id"] == null ? undefined : json["picture_id"],
    url: json["url"] == null ? undefined : json["url"],
    id: json["id"] == null ? undefined : json["id"],
  };
}

export function BikeCreateUpdateToJSON(json: any): BikeCreateUpdate {
  return BikeCreateUpdateToJSONTyped(json, false);
}

export function BikeCreateUpdateToJSONTyped(
  value?: BikeCreateUpdate | null,
  ignoreDiscriminator: boolean = false,
): any {
  if (value == null) {
    return value;
  }

  return {
    serial_number: value["serialNumber"],
    manufacturer: value["manufacturer"],
    bike_brand_id: value["bikeBrandId"],
    model: value["model"],
    drive_type: BikeDriveTypeToJSON(value["driveType"]),
    frame_type: BikeFrameTypeToJSON(value["frameType"]),
    size: value["size"],
    colour: value["colour"],
    purchase_price: value["purchasePrice"],
    purchase_currency: CurrencyToJSON(value["purchaseCurrency"]),
    purchase_vat_rate: value["purchaseVatRate"],
    shop_id: value["shopId"],
    condition: BikeConditionToJSON(value["condition"]),
    picture_id: value["pictureId"],
    url: value["url"],
    id: value["id"],
  };
}
